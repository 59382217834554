import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto, SearchDto } from '../../dtos/api';
import {
  CategoryAttributeResultDto,
  CreateNewAdvertControlRequestDto,
  CreateProductRequestDto,
  CurrentInfoFromSiteByproductIdResultDto,
  InfoForTrendyolNewAdvertResultDto,
  ProductPaginationResponseResultDto,
  SearchProductResultDto,
  UpdateProductRequestDto,
  UpdateProductResultDto,
} from '../../dtos/product';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private apiService: ApiService) {}

  create(
    createProductRequestDto: CreateProductRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.products.create,
      createProductRequestDto,
      null
    );
  }

  customerProducts(
    search: SearchDto
  ): Observable<ProductPaginationResponseResultDto> {
    return this.apiService.post<ProductPaginationResponseResultDto>(
      API_ROUTER_UTILS.url.products.productList,
      search,
      null
    );
  }

  customerProductByProductId(
    productId: string
  ): Observable<ResultDto<UpdateProductResultDto>> {
    return this.apiService.get<ResultDto<UpdateProductResultDto>>(
      null,
      API_ROUTER_UTILS.url.products.productByProductId,
      { productId: productId }
    );
  }

  deleteAllProductAndRelation(): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.batchoperations.deleteAllProductAndRelation,
      null,
      null
    );
  }

  categoryAttributesByCategoryId(
    categoryId: string
  ): Observable<ResultDto<CategoryAttributeResultDto[]>> {
    return this.apiService.get<ResultDto<CategoryAttributeResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.products.categoryAttributesByCategoryId,
      { categoryId: categoryId }
    );
  }

  createNewAdvertControl(
    createNewAdvertControlRequestDto: CreateNewAdvertControlRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.products.createNewAdvertControl,
      createNewAdvertControlRequestDto,
      null
    );
  }

  infoForTrendyolNewAdvertByProdoctId(
    productId: string,
    storeId: string
  ): Observable<ResultDto<InfoForTrendyolNewAdvertResultDto>> {
    return this.apiService.get<ResultDto<InfoForTrendyolNewAdvertResultDto>>(
      null,
      API_ROUTER_UTILS.url.products.infoForTrendyolNewAdvertByProdoctId,
      { productId: productId, storeId: storeId }
    );
  }

  currentInfoFromSiteByProductId(
    productId: string,
    storeTypeName: string
  ): Observable<ResultDto<CurrentInfoFromSiteByproductIdResultDto>> {
    return this.apiService.get<
      ResultDto<CurrentInfoFromSiteByproductIdResultDto>
    >(null, API_ROUTER_UTILS.url.products.currentInfoFromSiteByProductId, {
      productId: productId,
      storeTypeName: storeTypeName,
    });
  }

  allCurrentInfoFromSite(): Observable<
    ResultDto<CurrentInfoFromSiteByproductIdResultDto[]>
  > {
    return this.apiService.get<
      ResultDto<CurrentInfoFromSiteByproductIdResultDto[]>
    >(null, API_ROUTER_UTILS.url.products.allCurrentInfoFromSite);
  }

  allSearch(
    searchKey: string
  ): Observable<ResultDto<SearchProductResultDto[]>> {
    return this.apiService.get<ResultDto<SearchProductResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.products.allSearch,
      { searchKey: searchKey }
    );
  }

  productByBarcodeOrStockCode(
    barcodeOrStockCode: string
  ): Observable<ResultDto<SearchProductResultDto>> {
    return this.apiService.get<ResultDto<SearchProductResultDto>>(
      null,
      API_ROUTER_UTILS.url.products.productByBarcodeOrStockCode,
      { barcodeOrStockCode: barcodeOrStockCode }
    );
  }


  updateProductStock(
    request: UpdateProductRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.products.updateProductStock,
      request,
      null
    );
  }
}

import { ProductImageResultDto } from "../product-image";
import { ProductVarianterValueResultDto } from "../product-varianter";

export class ProductMatchResultDto {
  constructor(
    images: ProductImageResultDto[],
    varianterValues: ProductVarianterValueResultDto[],
    title?:string,
    brandName?:string,
    categoryName?:string,
    barcode?: string,
    productVarianterId?: string,
    productId?:string,
    stockCode?: string,
    stockCount?: number,
    salePrice?: number,
  ) {
    this.stockCount = stockCount;
    this.salePrice = salePrice;
    this.images = images;
    this.varianterValues = varianterValues;
    this.title = title;
    this.brandName = brandName;
    this.categoryName = categoryName;
    this.barcode = barcode;
    this.productVarianterId = productVarianterId;
    this.productId = productId;
    this.stockCode = stockCode;
  }

  stockCount: number;
  salePrice: number;
  images: ProductImageResultDto[];
  varianterValues: ProductVarianterValueResultDto[];
  title?:string;
  brandName?:string;
  categoryName?:string;
  barcode?: string;
  productVarianterId?: string;
  productId?:string;
  stockCode?: string;
}

<table
  class="table table-bordered"
  *ngIf="otherSellerDto != undefined && otherSellerDto.listings.length > 0"
>
  <thead>
    <th>Satıcı Adı</th>
    <th>Şehir</th>
    <th>Mağaza Puanı</th>
    <th>Satış Fiyatı</th>
    <th></th>
  </thead>
  <tbody *ngFor="let item of otherSellerDto.listings">
    <tr>
      <td>{{ item.merchantName }}</td>
      <td>{{ item.merchantCity }}</td>
      <td>{{ item.merchantRatingSummary.lifeTimeRating }}</td>
      <td>{{ item.price.amount | priceFormat }} {{ item.price.currency }}</td>
      <td>
        <a
          target="_blank"
          href="{{ otherSellerDto.productUrl }}?magaza={{ item.merchantName }}"
          class="fa fa-link"
        ></a>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="otherSellerDto != undefined && otherSellerDto.listings.length == 0">
  <ngb-alert [type]="'warning'" [dismissible]="false">
    Bu ürüne ait satıcı bilgisi bulunmamaktadır.
  </ngb-alert>
</div>

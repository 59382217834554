import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-auto-pricing-change-step-type',
  templateUrl: './auto-pricing-change-step-type.component.html',
  styleUrls: ['./auto-pricing-change-step-type.component.scss'],
})
export class AutoPricingChangeStepTypeComponent implements OnInit {
  @Input() autoPricingForm: UntypedFormGroup;
  @Output() changeStepTypeValueEmitter= new EventEmitter<string>();

  changeStepTypeValue: string = '1';
  constructor() {}

  ngOnInit(): void {}

  changeChangeStepType($event: any) {
    var value = $event.target.value;
    if (value == '1') {
      this.autoPricingForm
        .get('changeStepAmount')
        .setValidators([Validators.required]);

      this.autoPricingForm.get('changeStepPercent').clearValidators();

      this.autoPricingForm!.controls[
        'changeStepAmount'
      ].updateValueAndValidity();
      this.autoPricingForm!.controls[
        'changeStepPercent'
      ].updateValueAndValidity();
    } else {
      this.autoPricingForm
        .get('changeStepPercent')
        .setValidators([Validators.required]);

      this.autoPricingForm.get('changeStepAmount').clearValidators();

      this.autoPricingForm!.controls[
        'changeStepAmount'
      ].updateValueAndValidity();
      this.autoPricingForm!.controls[
        'changeStepPercent'
      ].updateValueAndValidity();
    }

    this.changeStepTypeValueEmitter.emit(value);
  }
}

export class SalePackagesComponentResultDto {
  constructor(
    uniqueId: string,
    packets: SalePackagesComponentPacketResultDto[]
  ) {
    this.uniqueId = uniqueId;
    this.packets = packets;
  }

  uniqueId: string;
  packets: SalePackagesComponentPacketResultDto[];
}

export class SalePackagesComponentPacketResultDto {
  constructor(packedItems: SalePackagesComponentPacketItemResultDto[]) {
    this.packedItems = packedItems;
  }

  packedItems: SalePackagesComponentPacketItemResultDto[];
}

export class SalePackagesComponentPacketItemResultDto {
  constructor(
    productName: string,
    quantity: number,
    cargoCompany: string,
    productImageBase64Url?: string,
    stringOrderLineId?: string,
    longOrderLineId?: number
  ) {
    this.productName = productName;
    this.quantity = quantity;
    this.cargoCompany = cargoCompany;
    this.productImageBase64Url = productImageBase64Url;
    this.stringOrderLineId = stringOrderLineId;
    this.longOrderLineId = longOrderLineId;
  }

  productName: string;
  quantity: number;
  cargoCompany: string;
  productImageBase64Url?: string;
  stringOrderLineId?: string;
  longOrderLineId?: number;
}

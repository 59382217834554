import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { ResultDto } from "../../dtos/api";
import { AnswerQuestionRequestDto, NewQuestionSearchAndSaveResultDto, QuestionFunctionResultDto } from "../../dtos/question";
import { API_ROUTER_UTILS } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class TrendyolQuestionService {
  constructor(private apiService: ApiService) {}

  newQuestions(): Observable<ResultDto<NewQuestionSearchAndSaveResultDto[]>> {
    return this.apiService.get<ResultDto<NewQuestionSearchAndSaveResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyolQuestion.newQuestions
    );
  }

  answerQuestion(answerQuestion: AnswerQuestionRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.trendyolQuestion.answer,
      answerQuestion,
      null
    );
  }
}

export class SearchDto {
  advancedSearch?: AdvancedSearch;
  keyword?: string;
  advancedFilter?: AdvancedFilter;
  pageNumber: number | undefined;
  pageSize?: number;
  orderBy?: string[];
}

export class AdvancedSearch {
  fields: string[] | undefined;
  keyword: string | undefined;
}

export class AdvancedFilter {
  logic: string | undefined;
  filters: AdvancedFilter[] | undefined;
  field: string | undefined;
  operator: string | undefined;
  value: string | undefined;
}

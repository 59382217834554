import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/apis';
import { ResultDto } from 'src/app/core/dtos/api';
import { API_ROUTER_UTILS } from 'src/app/core/utils';
import {
  AutoPricingPaginationRequestDto,
  AutoPricingPaginationResultDto,
  CreateAutoPricingPreviewResultDto,
  CreateAutoPricingRequestDto,
} from '../../dtos/auto-pricing';

@Injectable({
  providedIn: 'root',
})
export class AutoPricingService {
  constructor(private apiService: ApiService) {}

  search(
    searchDto: AutoPricingPaginationRequestDto
  ): Observable<ResultDto<AutoPricingPaginationResultDto>> {
    return this.apiService.post<ResultDto<AutoPricingPaginationResultDto>>(
      API_ROUTER_UTILS.url.autoPricing.search,
      searchDto,
      null
    );
  }

  createAutoPricing(
    request: CreateAutoPricingRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.autoPricing.createAutoPricing,
      request,
      null
    );
  }

  createAutoPricingPreview(
    request: CreateAutoPricingRequestDto
  ): Observable<ResultDto<CreateAutoPricingPreviewResultDto[]>> {
    return this.apiService.post<ResultDto<CreateAutoPricingPreviewResultDto[]>>(
      API_ROUTER_UTILS.url.autoPricing.createAutoPricingPreview,
      request,
      null
    );
  }
}

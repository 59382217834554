<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Trendyol Ürün Otamatik Fiyatlandırma
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="closeTrendyolAutoPricingModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <form class="theme-form" [formGroup]="autoPricingForm">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Min. Fiyat</mat-label>
                <input
                  type="text"
                  formControlName="minPrice"
                  matInput
                  onlyDecimalInput
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Değişim Tutarı</mat-label>
                <input
                  type="text"
                  formControlName="changeAmount"
                  matInput
                  onlyDecimalInput
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Min. Satıcı Puanı</mat-label>
                <input
                  type="number"
                  formControlName="minSellerScore"
                  matInput
                  onlyNumbers
                  [min]="1"
                  [max]="10"
                />
                <mat-hint align="start"
                  ><strong
                    >(İsteğe bağlı olarak) Referans alınacak mininum satıcı
                    puanı</strong
                  >
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <app-auto-pricing-actived [autoPricingForm]="autoPricingForm">
              </app-auto-pricing-actived>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="submit"
    class="btn btn-primary"
    (click)="createAutoPricing(autoPricingForm.value)"
  >
    Oluştur
  </button>
  <button class="btn btn-light" (click)="closeTrendyolAutoPricingModal()">
    Kapat
  </button>
</div>

<ng-template #otherSellerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Diğer Satıcılar</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeTrendyolOtherSellersModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div *ngIf="trendyolProductId != undefined">
        <app-trendyol-other-sellers
          [trendyolProductId]="trendyolProductId"
        ></app-trendyol-other-sellers>
      </div>
    </div>
  </div>
</ng-template>

<div class="form-group m-form__group" [formGroup]="operationFormGroup">
  <div
    class="input-group"
    [ngClass]="{
      'input-error': operationFormGroup.invalid && submitted
    }"
  >
    <div class="input-group-prepend">
      <span class="input-group-text"
      [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >İşlem<span class="required-input">*</span>
      </span>
    </div>
    <ng-select
      class="form-control"
      formControlName="operationId"
      placeholder="Seçiniz..."
    >
      <ng-option [value]="1"> Min. Fiyat Altına </ng-option>
    </ng-select>
  </div>
</div>

<div class="form-group m-form__group" [formGroup]="categoryForm">
  <div class="input-group">
    <div class="input-group-prepend">
      <span
        class="input-group-text"
        [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >Kategori
        <span *ngIf="required != undefined && required" class="required-input"
          >*</span
        ></span
      >
    </div>
    <ng-select
      class="form-control"
      placeholder="Seçiniz..."
      formControlName="categoryId"
    >
      <ng-option *ngFor="let item of customerCategories" [value]="item.id">{{
        item.name
      }}</ng-option>
    </ng-select>
    <!-- <ng-select
        [items]="customerCategories"
        class="form-control"
        placeholder="Kategori Seçiniz..."
        required=""
        formControlName="categoryId"
        bindLabel="name"
      >
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div>
          <span>{{item.name}}</span>
        </div>
        <small>
          <b>{{item.rootCategoryName}}</b>
        </small>
      </ng-template>
         <ng-option *ngFor="let item of customerCategories" [value]="item.id">
          <div>
            <span>{{item.name}}</span>
          </div>
          <small>
            <b>{{item.rootCategoryName}}</b>
          </small>
        </ng-option>
      </ng-select> -->
  </div>
</div>

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[onlyDecimalInput]' })
export class OnlyDecimalInputDirective {
  public inputElement: ElementRef;

  constructor(el: ElementRef) {
    this.inputElement = el;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event:any) {
    this.decimalOnly(event);
  }

  decimalOnly(event:any): void {
    const e = <KeyboardEvent>event;
    const code = e.which ? e.which : e.keyCode;

    // 8: Backspace, 9: Tab , 13: Enter, 27: Escape
    if (
      [8, 9, 13, 27, 44].indexOf(code) !== -1 ||
      // Allow: Ctrl+A
      (code === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (code === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (code === 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (code === 88 && e.ctrlKey === true)
    ) {
      // Check comma count after the input
      const inputValue: string = this.inputElement.nativeElement.value;
      const commaCount = (inputValue.match(/,/g) || []).length;

      if (commaCount >= 1 && (code === 44 || e.key === ',')) {
        e.preventDefault();
        return;
      }

      return;
    }

    const currentCursorPosition =
      this.inputElement.nativeElement.selectionStart;
    const inputValue: string = this.inputElement.nativeElement.value;

    // Check if the inputValue already contains a comma
    const commaIndex = inputValue.indexOf(',');
    if (commaIndex !== -1) {
      // Check if the cursor is after the comma and if there are already two decimal places
      if (
        currentCursorPosition > commaIndex &&
        inputValue.substring(commaIndex).length >= 3
      ) {
        e.preventDefault();
      }
    }

    // Allow only digits and a single comma at the beginning or after an existing digit
    if (
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) ===
        -1 ||
      (e.key === ',' &&
        (inputValue.indexOf(',') !== -1 ||
          currentCursorPosition === 0 ||
          inputValue.indexOf(',') !== -1))
    ) {
      e.preventDefault();
    }
  }
}

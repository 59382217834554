<div class="form-group m-form__group" [formGroup]="brandForm">
  <div class="input-group" [ngClass]="{
    'input-error': brandForm.controls['brandId'].invalid && submitted
  }">
    <div class="input-group-prepend">
      <span class="input-group-text font-weight-bold"
        >Marka <span class="required-input">*</span></span
      >
    </div>
    <ng-select
      class="form-control"
      placeholder="Trendyol marka Seçiniz..."
      required=""
      formControlName="brandId"
      (search)="onSearch($event)"
      (change)="onChange($event)"
    >
      <ng-option *ngFor="let item of brands" [value]="item.id">{{
        item.name
      }}</ng-option>
    </ng-select>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ResultDto } from 'src/app/core/dtos/api';
import { HepsiBuradaCreateProductCategoryResultDto } from 'src/app/core/dtos/hepsiburada';
import { TrendyolCreateProductCategoryResultDto } from 'src/app/core/dtos/trendyol';
import { HepsiBuradaService } from 'src/app/core/services/hepsiburada/hepsi-burada.service';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-hepsi-burada-categories',
  templateUrl: './form-select-hepsi-burada-categories.component.html',
  styleUrls: ['./form-select-hepsi-burada-categories.component.scss'],
})
export class FormSelectHepsiBuradaCategoriesComponent implements OnInit {
  @Input() categoryForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Output() changeCategoryEmit = new EventEmitter<string | undefined>();

  categories: HepsiBuradaCreateProductCategoryResultDto[] = [];
  constructor(
    private hepsiBuradaService: HepsiBuradaService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  forCreateProductCategories(searchKey: string) {
    this.hepsiBuradaService.forCreateProductCategories(searchKey).subscribe(
      (data: ResultDto<TrendyolCreateProductCategoryResultDto[]>) => {
        this.categories = data.data;
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  onSearch($event: any) {
    if ($event.term.length >= 3) {
      this.forCreateProductCategories($event.term);
    }
  }

  onChange($event: number | undefined) {
    if ($event == undefined) {
      this.changeCategoryEmit.emit(undefined);
    } else {
      let trendyolCategory = this.categories.filter(
        (element) => element.categoryId == $event
      )[0];

      this.changeCategoryEmit.emit(trendyolCategory.id);
    }
  }
}

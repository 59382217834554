<div class="form-group m-form__group" [formGroup]="autoPricingForm">
  <div
    class="input-group"
    [ngClass]="{
      'input-error':
        autoPricingForm.get('changeStepPercent').invalid && submitted
    }"
  >
    <div class="input-group-prepend">
      <span
        class="input-group-text"
        [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >Değişim Yüzdesi <span class="required-input">*</span></span
      >
    </div>
    <input
      class="form-control"
      [type]="'number'"
      formControlName="changeStepPercent"
      onlyNumbers
      [max]="100"
      [min]="1"
    />
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import { API_ROUTER_UTILS } from '../../utils';
import { NewOrderSearchAndSaveResultDto } from '../../dtos/sale';
import { TrendyolOrderDetailResultDto } from '../../dtos/trendyol';
import {
  HepsiBuradaChangeCargoCompanyRequestDto,
  HepsiBuradaChangeableCargoCompanyResultDto,
  HepsiBuradaOrderDetailResultDto,
} from '../../dtos/hepsiburada';

@Injectable({
  providedIn: 'root',
})
export class HepsiBuradaOrderService {
  constructor(private apiService: ApiService) {}

  newSales(): Observable<ResultDto<NewOrderSearchAndSaveResultDto[]>> {
    return this.apiService.get<ResultDto<NewOrderSearchAndSaveResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.hepsiBuradaOrders.newOrder
    );
  }

  detail(
    hepsiBuradaOrderId: string
  ): Observable<ResultDto<HepsiBuradaOrderDetailResultDto>> {
    return this.apiService.get<ResultDto<HepsiBuradaOrderDetailResultDto>>(
      null,
      API_ROUTER_UTILS.url.hepsiBuradaOrders.detail,
      { hepsiBuradaOrderId: hepsiBuradaOrderId }
    );
  }

  packedChangeableCargoCompanies(
    orderId: string
  ): Observable<ResultDto<HepsiBuradaChangeableCargoCompanyResultDto[]>> {
    return this.apiService.get<
      ResultDto<HepsiBuradaChangeableCargoCompanyResultDto[]>
    >(
      null,
      API_ROUTER_UTILS.url.hepsiBuradaOrders.packedChangeableCargoCompanies,
      { orderId: orderId }
    );
  }

  packedChangeCargoCompany(
    request: HepsiBuradaChangeCargoCompanyRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<
      ResultDto
    >(
      API_ROUTER_UTILS.url.hepsiBuradaOrders.packedChangeCargoCompany,
      request,
      null
    );
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { PaginationResponseDto, ResultDto, SearchDto } from "../../dtos/api";
import { CreateVarianterDto, CreateVarianterValueDto, UpdateVarianterDto, UpdateVarianterValueDto, VarianterResultDto, VarianterValueFilterDto, VarianterValueMainResultDto } from "../../dtos/varianter";
import { API_ROUTER_UTILS } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class VarianterService {
  constructor(private apiService: ApiService) {}

  search(
    searchDto: SearchDto
  ): Observable<ResultDto<PaginationResponseDto<VarianterResultDto[]>>> {
    return this.apiService.post<
      ResultDto<PaginationResponseDto<VarianterResultDto[]>>
    >(API_ROUTER_UTILS.url.varianters.search, searchDto, null);
  }

  create(createVarianterDto: CreateVarianterDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.varianters.create,
      createVarianterDto,
      null
    );
  }

  update(updateVarianterDto: UpdateVarianterDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.varianters.update,
      updateVarianterDto,
      null
    );
  }

  delete(varianterId: string): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.varianters.delete,
      null,
      null,
      { varianterId: varianterId }
    );
  }

  all(): Observable<ResultDto<VarianterResultDto[]>> {
    return this.apiService.get<ResultDto<VarianterResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.varianters.all,
      null
    );
  }

  searchVariantValues(
    searchDto: VarianterValueFilterDto
  ): Observable<ResultDto<VarianterValueMainResultDto>> {
    return this.apiService.post<ResultDto<VarianterValueMainResultDto>>(
      API_ROUTER_UTILS.url.varianters.searchVarianterValues,
      searchDto,
      null
    );
  }

  createVarianterValue(
    createVarianterValueDto: CreateVarianterValueDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.varianters.createVarianterValue,
      createVarianterValueDto,
      null
    );
  }

  updateVarianterValue(
    updateVarianterValueDto: UpdateVarianterValueDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.varianters.updateVarianterValue,
      updateVarianterValueDto,
      null
    );
  }

  deleteVarianterValue(varianterValueId: string): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.varianters.deleteVarianterValue,
      null,
      null,
      { varianterValueId: varianterValueId }
    );
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { PaginationResponseDto, ResultDto, SearchDto } from "../../dtos/api";
import { ProductStockTransactionResultDto } from "../../dtos/product-stock-transaction";
import { API_ROUTER_UTILS } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class ProductStockTransactionService {
  constructor(private apiService: ApiService) {}

  search(searchDto: SearchDto): Observable<ResultDto<PaginationResponseDto<ProductStockTransactionResultDto>>> {
    return this.apiService.post<ResultDto<PaginationResponseDto<ProductStockTransactionResultDto>>>(
      API_ROUTER_UTILS.url.productStockTransactions.search,
      searchDto,
      null
    );
  }

  byProductId(productId: string): Observable<ResultDto<ProductStockTransactionResultDto[]>> {
    return this.apiService.get<ResultDto<ProductStockTransactionResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.productStockTransactions.byProductId,
      { productId: productId }
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { API_ROUTER_UTILS } from '../../utils';
import { CountryResultDto, CurrencyCodeResultDto } from '../../dtos/value-table';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private apiService: ApiService) {}

  getCountries(): Observable<CountryResultDto[]> {
    return this.apiService.get<CountryResultDto[]>(
      null,
      API_ROUTER_UTILS.url.valueTable.countries
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ResultDto } from 'src/app/core/dtos/api';
import { HepsiburadaOtherSellerDto } from 'src/app/core/dtos/hepsiburada';
import { HepsiBuradaProductService } from 'src/app/core/services/hepsiburada';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-hepsiburada-other-sellers',
  templateUrl: './hepsiburada-other-sellers.component.html',
  styleUrls: ['./hepsiburada-other-sellers.component.scss'],
})
export class HepsiburadaOtherSellersComponent implements OnInit {
  @Input() hepsiburadaProductId: string;
  otherSellerDto: HepsiburadaOtherSellerDto;

  constructor(
    private hepsiburadaProductService: HepsiBuradaProductService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.otherSellers(this.hepsiburadaProductId);
  }

  otherSellers(id: string) {
    this.hepsiburadaProductService.otherSellers(id).subscribe(
      (data: ResultDto<HepsiburadaOtherSellerDto>) => {
        if (data.success) {
          this.otherSellerDto = data.data;
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorText = httpErrorResponseUtil(error);
        this.messageService.error(errorText);
      }
    );
  }
}

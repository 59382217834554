<div class="form-group m-form__group" [formGroup]="autoPricingForm">
  <div
    class="input-group"
    [ngClass]="{
      'input-error':
        autoPricingForm.get('changeStepAmount').invalid && submitted
    }"
  >
    <div class="input-group-prepend">
      <span
        class="input-group-text"
        [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >Değişim Tutarı <span class="required-input">*</span></span
      >
    </div>
    <input
      class="form-control"
      [type]="'text'"
      formControlName="changeStepAmount"
      currencyInput
    />
  </div>
</div>

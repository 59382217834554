<div class="form-group m-form__group" [formGroup]="storeForm">
  <div class="input-group" [ngClass]="{
    'input-error': storeForm.controls['storeId'].invalid && submitted
  }">
    <div class="input-group-prepend">
      <span
        class="input-group-text"
        [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >Mağaza
        <span *ngIf="required != undefined && required" class="required-input"
          >*</span
        ></span
      >
    </div>
    <ng-select
      class="form-control"
      placeholder="Seçiniz..."
      formControlName="storeId"
    >
      <ng-option *ngFor="let store of stores" [value]="store.id">
        <span *ngIf="store.storeImageUrl != null"
          ><img
            width="15"
            height="15"
            [src]="store.storeImageUrl" /></span
        ><span>{{ store.name }}</span>
      </ng-option>
    </ng-select>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import {
  AnswerQuestionRequestDto,
  NewQuestionSearchAndSaveResultDto,
} from '../../dtos/question';
import { API_ROUTER_UTILS } from '../../utils';
import { HepsiBuradaAnswerQuestionRequestDto } from '../../dtos/hepsiburada';
import { HepsiBuradaQuestionConversationResultDto } from '../../dtos/hepsiburada/hepsi-burada-question/hepsi-burada-question-converstion-result.dto';

@Injectable({
  providedIn: 'root',
})
export class HepsiBuradaQuestionService {
  constructor(private apiService: ApiService) {}

  newQuestions(): Observable<ResultDto<NewQuestionSearchAndSaveResultDto[]>> {
    return this.apiService.get<ResultDto<NewQuestionSearchAndSaveResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.hepsiBuradaQuetion.newQuestions
    );
  }

  conversations(questionId: string): Observable<ResultDto<HepsiBuradaQuestionConversationResultDto[]>> {
    return this.apiService.get<ResultDto<HepsiBuradaQuestionConversationResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.hepsiBuradaQuetion.conversationsById,
      { questionId: questionId }
    );
  }

  answerQuestion(
    answerQuestion: HepsiBuradaAnswerQuestionRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.hepsiBuradaQuetion.answer,
      answerQuestion,
      null
    );
  }
}

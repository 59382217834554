import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryParamInterface } from "../interfaces/query-param.interface";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private readonly END_POINT: string;

  constructor(private http: HttpClient) {
    this.END_POINT = environment.apiUrl;
  }

  get<returnType>(
    id: number | null,
    route: string,
    qp: QueryParamInterface = {}
  ): Observable<returnType> {
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http["get"](
      `${this.END_POINT}/${route}${id ? "/" + id : ""}${cfqu}`
    ) as Observable<returnType>;
  }

  post<returnType>(
    route: string,
    data: unknown,
    id: number | null,
    qp: QueryParamInterface = {}
  ): Observable<returnType> {
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http["post"](
      `${this.END_POINT}/${route}${id ? "/" + id : ""}${cfqu}`,
      data
    ) as Observable<returnType>;
  }

  private correctFormatForQueryUrl(qp: QueryParamInterface): string {
    if (qp == null) {
      return "";
    }
    const qpAsStr = this.mapQueryParamsToUrl(qp);
    return qpAsStr.length === 0 ? "" : `?${qpAsStr.join("&")}`;
  }

  private mapQueryParamsToUrl(qp: QueryParamInterface): Array<string> {
    return Object.keys(qp).map((key: string) => {
      return `${key}=${qp[key]}`;
    });
  }
}

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <!-- <h3><b>1. Taraflar</b></h3>

      <p>
        <b>1.1. Şirket:</b> Har Yazılım, Fertek Mah. Ömer Halisdemir Küme Evler
        Teknopark No:31 İç Kapı No:216 Merkez/Niğde/Türkiye (bundan sonra
        "Şirket" olarak anılacaktır).
      </p>

      <p>
        <b>1.2. Marka:</b> Harpazar (bundan sonra "Marka" olarak anılacaktır).
      </p>

      <p>
        <b>1.3. Uygulama:</b> Harpazar uygulaması (bundan sonra "Uygulama"
        olarak anılacaktır).
      </p>
      <p>
        <b>1.4. Kullanıcı:</b> Uygulamayı kullanan gerçek veya tüzel kişi
        (bundan sonra "Kullanıcı" olarak anılacaktır).
      </p> -->

      <h3><b>1.Giriş</b></h3>
      <p>
        İşbu kullanıcı sözleşmesinde, sözleşmenin eklerinde ve harpazar.com ve
        haryazilim.com.tr sitelerinde yer alan tüm kurallar Har Yazılım'ın
        aracılığıyla harpazar.com ve haryazilim.com.tr sunduğu hizmetlerin şart
        ve koşullarını belirtmektedir. İşbu sözleşmenin ekleri ve harpazar.com
        ve haryazilim.com.tr sitelerin de sunulan Har Yazılım hizmetlerinin
        kullanımına ilişkin tüm yazılı süreçler, açıklamalar, ek diğer tüm
        dokümanlar işbu sözleşmenin ayrılmaz birer parçası olarak kabul
        edilecektir.
      </p>

      <h3><b>KİŞİSEL VERİLERİN KORUNMASI HAKKINDA BİLGİLENDİRME</b></h3>

      <p>
        6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) 24 Mart 2016
        tarihinde TBMM’de kabul edilmiş ve 07 Nisan 2016 tarihli Resmi Gazete
        yayımlanarak yürürlüğe girmiştir. Yürüttüğümüz faaliyet sırasında sizden
        aldığımız kişisel bilgileriniz bakımından Veri Sorumlusu olarak
        addedilmekteyiz. Veri Sorumlusu olarak, kişisel bilgileriniz aşağıda
        açıklandığı çerçevede kaydedilebilecek, saklanabilecek,
        güncellenebilecek, sınıflandırılabilecek, mevzuatın izin verdiği
        hallerde 3. Kişilere ve/veya yurt dışına açıklanabilecek ve/veya
        devredilebilecek ve KVKK’da sayılan diğer şekillerde işlenebilecektir.
      </p>

      <h3><b>2.Taraflar</b></h3>

      <p>
        İşbu sözleşme harpazar.com sitesinde kullanıcı bilgileri bulunan ve/veya
        hizmeti satın almış olan ile (bundan böyle kullanıcı olarak anılacaktır)
        Türk hukukuna göre kurulmuş olan ve varlığını Fertek Mah. Ömer
        Halisdemir Küme Evler Teknopark No:31 İç Kapı No:216
        Merkez/Niğde/Türkiye adresinde sürdürmek te olan Har Yazılım (Bundan
        böyle harpazar olarak anılacaktır) arasında yapılmaktadır. Kullanıcı,
        harpazar.com veya haryazilim.com.tr sitelerine üye olduğunda işbu
        sözleşmenin hüküm ve şartlarını kabul etmiş sayılır. Harpazar ve
        Kullanıcı ayrı ayrı “Taraf”, birlikte “Taraflar” olarak anılacaklardır.
        Taraflar aşağıdaki şartlarda mutabık kalmışlardır.
      </p>

      <h3><b>3.Tanımlar</b></h3>

      <p>
        <b>Web Sitesi:</b> Mülkiyeti Harpazar'a ait olan ve Harpazar'ın işbu
        sözleşme ile belirlenen hizmetlerini üzerinde sunmakta olduğu
        harpazar.com alan adına sahip internet sitesini ifade eder.
      </p>

      <p>
        <b>E-Ticaret Sitesi:</b> Harpazar tarafından hazırlanmış olan Web Sitesi
        aracılığıyla, kullanıcının ürün veya hizmetlerini listeleyeceği ve
        satışa çıkaracağı, bu işlemlerin yapılabilmesi için web servisi desteği
        veren tüm elektronik ticaret internet sitelerini ifade eder.
      </p>

      <p>
        <b>Hizmet/Hizmetler:</b> Kullanıcının işbu sözleşme içerisinde tanımlı
        olan iş ve işlemlerini gerçekleştirmeleri amacıyla Harpazar tarafından
        Web Sitesinde sunulan ürün yayınlama, ürün güncelleme, ürün silme ve
        benzeri uygulamaları ifade eder.
      </p>

      <p>
        <b>Alıcı:</b>Kullanıcının, Web Sitesi aracılığı ile e-ticaret
        sitelerinde yayınladığı ürün veya hizmetleri satın alan gerçek veya
        tüzel kişileri ifade eder.
      </p>

      <p>
        <b>Kaynak Dosya:</b> Kullanıcı veya tedarikçi tarafından hazırlanmış
        olan, Web Sitesinin desteklediği uzantılara (xml, excel vb.) sahip olan,
        içeriğinde kullanıcının e-ticaret sitelerine yüklemek istediği ürün veya
        hizmet bilgilerini içeren dosyadır.
      </p>

      <p>
        <b>Kullanıcı Paneli:</b> Kullanıcının Harpazar tarafından Web Sitesinde
        sunulan uygulamalardan ve hizmetlerden faydalanabilmesi için gerekli
        işlemleri gerçekleştirebildiği, kullanıcı tarafından belirlenen
        kullanıcı adı ve şifre ile giriş yapılabilen kullanıcıya özel sayfaların
        yer aldığı paneli ifade eder.
      </p>

      <h3><b>4.Sözleşmenin Konusu</b></h3>

      <p>
        İşbu sözleşmenin konusunu, Web Sitesi üzerinde Harpazar tarafından
        sunulan Hizmetlerden, Kullanıcının yararlanmasına ilişkin şart ve
        koşulların belirlenmesi ve bu doğrultuda tarafların hak ve
        yükümlülüklerinin tespiti oluşturmaktadır. İşbu Sözleşme, yalnızca
        Taraflar arasında bağlayıcı olup, Harpazar tarafından Kullanıcıya
        sunulan Hizmetlere yönelik şekil ve şartları kapsamaktadır. Kullanıcı
        ile Alıcılar, Kullanıcı ile e-Ticaret Siteleri ve Kullanıcı ile
        Tedarikçiler arasındaki ilişki işbu Sözleşme'nin kapsamına
        girmemektedir.
      </p>

      <h3><b>5. Harpazar Hak ve Yükümlülükleri</b></h3>

      <p>
        Web Sitesi üzerinde yer alan her türlü içeriğin tek hak sahibi Harpazar
        olacaktır. Harpazar'ın önceden yazılı rızası alınmaksızın Kullanıcı
        tarafından Web Sitesinde yer alan içeriğin bütününün veya bir bölümünün
        ticari amaçlarla kopyalanması, işlenmesi, kullanılması veya umuma arz
        edilmesi kesinlikle yasaktır. Kullanıcı, Web Sitesi aracılığıyla
        e-Ticaret Sitelerine iletmiş olduğu her türlü ilan, içerik ve görsel
        üzerinde her daim hak sahibi olmaya devam edecektir. Ancak kullanıcı,
        Web Sitesinde yer alan Kullanıcı Panelini kullanarak düzenlediği ilan
        şablonlarını harici olarak başka internet sitelerinde kullanamaz. Bu
        durum gerçekleştiğinde Harpazar'ın sözleşmeyi tek taraflı feshetme hakkı
        saklıdır.
      </p>

      <p>
        Harpazar, resmi idareler, yargı organları veya yetkili makamlar
        tarafından Kullanıcı hakkında evrak veya bilgi talep edilmesi halinde
        Kullanıcının kendisinde bulunan tüm bilgi ve belgelerini ilgili yetkili
        makamlarla paylaşabilecektir.
      </p>

      <p>
        Harpazar, Kullanıcı tarafından kayıt işlemi esnasında ve Üyelik boyunca
        temin edilen şahsi ve ticari bilgileri güvenli bir ortamda
        saklayacaktır.
      </p>

      <p>
        Harpazar, bu bilgileri Hizmetlerin tam ve gereği gibi işletilmesi için
        gereken uygulamaların yürütülmesi, istatistiki değerlendirmelerin
        yapılması amacıyla Harpazar 'ın faaliyetlerinin, uygulamalarının reklamı
        ve pazarlanması, ilanı ve sair amaçlarla kullanabilir ve yalnızca
        yukarıda belirtilen sebeplerle üçüncü kişilere açıklayabilir. Kullanıcı,
        kendine ait bilgilerin Harpazar tarafından işbu madde hükmüne göre
        kullanımına ve saklanmasına rıza gösterdiğini kabul ve beyan eder.
      </p>

      <p>
        Har Yazılım, Web Sitesinin tam ve gereği gibi çalışabilmesi ve güvenlik
        sebebiyle gerekli gördüğü her türlü önlemi alma ve değişiklik yapma
        hakkında sahiptir. İşbu amaçla Har Yazılım, sunulan hizmetleri ve
        içerikleri her zaman ve hiçbir bildirimde bulunmadan değiştirebilir;
        Kullanıcıların sisteme yükledikleri bilgileri ve içerikleri Kullanıcılar
        da dâhil olmak üzere üçüncü kişilerin erişimine kapatabilir veya
        silebilir. Harpazar hem güvenlik hem de ilgili mevzuatın gerektirdiği
        zorunluluklar gereği, Web Sitesi üzerinden gerçekleştirilen mesajlaşma
        da dâhil olmak üzere iletişim trafiğini her zaman izleyebilir, kontrol
        edebilir ve saklayabilir.
      </p>

      <p>
        Harpazar, hizmetleri geçici olarak durdurma, askıya alma ya da işbu
        Sözleşmenin feshiyle iptal etme hakkına her zaman sahip olacaktır. Bu
        durumlar Harpazar tarafından e-posta ile Kullanıcıya bildirilecektir.
        e-Posta adresi olarak Kullanıcının Harpazar panelinde kayıtlı adresi
        kullanılacaktır.
      </p>

      <p>
        Harpazar, Web Sitesi üzerinde sunulacak olan sözleşme içeriğini,
        hizmetleri, kampanya detaylarını, süreleri, ek imkânları ve teknik
        özellikleri önceden haber vermeksizin değiştirilebilecektir.
        Değişiklikler yayınlanma tarihinden itibaren geçerli olacaktır.
      </p>

      <h3><b>6.Kullanıcının Hak ve Yükümlülükleri</b></h3>

      <p>
        Kullanıcı, "Kullanıcı" sıfatını kazanabilmek için, öncelikle
        harpazar.com veya haryazilim.com.tr sitelerin de Harpazar tarafından
        talep edilen bilgileri eksiksiz olarak doldurmalıdır. İşbu sözleşme
        şartlarını kabul ederek üyelik işlemlerini ve bilgilerini tamamladığında
        üyeliği aktif olur ve Kullanıcı sıfatını kazanır. Harpazar 'ın
        kullanıcının başvurusunu sebep göstermeksizin reddetme ve/veya ek bilgi
        veya belge talep etme hakkı her zaman saklıdır. Başvuru sırasında
        Kullanıcı tarafından gerçeğe uygun olmayan yanlış bilgiler verilmesi
        nedeniyle doğabilecek tüm zararlardan bizzat Kullanıcı sorumlu
        olacaktır.
      </p>

      <p>
        Kullanıcı, ilanlarının e-ticaret sitesinde yayınlanmasını sağlayacak
        gerekli kaynak dosyaları kendisinin temin edeceğini kabul, beyan ve
        taahhüt eder. Kaynak dosyaların içeriğinden ve kullanıcının bu kaynak
        dosyayı kullanarak Kullanıcı Panelinde yapacağı her türlü işlemden
        Kullanıcı sorumludur.
      </p>

      <p>
        Kullanıcı, Web Sitesini kullanarak e-Ticaret sitelerinde yayınladığı
        ilanlara yönelik her türlü görsel, yazılı ve sair bilgi/içeriklerin
        gerçekliğinden, güvenliğinden, doğruluğundan ve hukuka ve/veya ahlaka
        uygunluğundan bizzat sorumlu olmayı; Harpazar 'ın bu hususlarda herhangi
        bir sorumluluğu olmadığını, bu bilgi/içerikler sebebiyle Harpazar 'ın
        uğrayacağı zararlardan ve zarar gören üçüncü kişilere Harpazar
        tarafından yapılmış olan ödemelerden gayrikabili rücuolarak Harpazar 'a
        karşı sorumlu olmayı peşinen kabul, beyan ve taahhüt eder.
      </p>

      <p>
        Kullanıcı, Harpazar tarafından Web Sitesinde kendisine sağlanan alanda
        hukuka ve/veya ahlaka aykırı, üçüncü kişilerin her türlü haklarını,
        Fikri ve Sınai Haklar yasası gereğince korunan herhangi bir hakkı ihlal
        eden bir içerik bulundurmayacağını ve yayınlamayacağını kabul ve taahhüt
        etmektedir. Aksi takdirde her türlü zarar ve ziyandan kullanıcı
        sorumludur.
      </p>

      <p>
        Kullanıcı, başvuru esnasında vermiş olduğu bilgilerde meydana
        gelebilecek her türlü değişikliği, değişikliğin meydana gelmesinden
        itibaren en geç 15 gün içinde, Harpazar'a sitede bulunan
        destek/iletişim, ihtarname veya iadeli taahhütlü posta yollarından
        biriyle bildirmekle yükümlüdür. Bu bildirimin yapılmaması sebebiyle
        Harpazar'ın herhangi bir zarara uğraması halinde, Harpazar'ı
        Kullanıcıdan her türlü zararını talep hakkı saklıdır. Kullanıcı,
        e-Ticaret Sitelerindeki ilanlarda sergilediği mal ve hizmetlerin
        satışının ve teşhirinin yürürlükteki mevzuat doğrultusunda herhangi bir
        hukuka ve/veya ahlaka aykırılık yaratmayacağını, hak ihlaline sebep
        olmayacağını, söz konusu ilan ve içeriklerin ilgili olduğu mal ve
        hizmetlerin internet üzerinde yayınlanması, satışa arz edilmesi ve
        satılması için tüm hak, yetki ve sorumluluğun kendisinde olduğunu kabul,
        beyan ve taahhüt etmektedir.
      </p>

      <p>
        Kullanıcı tarafından Harpazar'ın kişilik haklarına internet,sosyal medya
        gibi ortam veya platformlarda yapılan yayınlar ve paylaşımlar gönderilen
        e postalar ile saldırı nedeniyle, Harpazar'ın müşteri kaybı, şirket
        hisselerinin değer kaybı, tiraj azalması, satışlardaki azalma, iş kaybı
        gibi zararlar gerçekleşebileceğinden uğranılan zararların tazminine
        ilişkin Türk Medeni Kanunu'nun 25. maddesi ve Türk Borçlar Kanunu'nun
        58. maddesi hükümleri ile paylaşımların suç teşkil etmesi halinde Türk
        Ceza Kanunu'nun ilgili hükümleri gereği maddi tazminat, manevi tazminat
        ve cezai müeyyidelerin kendisine uygulanacağını kabul eder.
      </p>

      <p>
        Kullanıcı, Web Sitesi aracılığıyla e-Ticaret Sitelerinde kesinlikle
        taklit/sahte, izin gerektiren ve/veya izni olmayan ürün teşhir etmeyecek
        ve satmayacaktır. Ayrıca Kullanıcı, ürün listelemesi yaptığı e-Ticaret
        Sitelerindeki yasaklı içeriğini sürekli olarak takip etmek ve bu listede
        yer alan ürünlerin teşhirini ve satışını yapmamakla yükümlüdür.
        Kullanıcının bu listelerde yer alan ürünlerin teşhirini veya satışını
        yapması halinde, bu fiillerin hukuka ve/veya ahlaka aykırı lığından
        dolayı Harpazar'a yöneltilebilecek talepler veya 3. kişilerin telif ve
        marka ihlallerinden kaynaklanabilecek taleplerin tek muhatabı Kullanıcı
        olacaktır. Harpazar'ın bu meyanda hiçbir sorumluluğu bulunmayacaktır.
        Kullanıcı, bu madde hükmüne aykırı fiilleri sebebiyle Harpazar'ın
        uğrayacağı zararlardan ve zarar gören üçüncü kişilere Harpazar
        tarafından yapılmış olan ödemelerden gayrikabili rücu olarak Harpazar'a
        karşı sorumlu olmayı peşinen kabul, beyan ve taahhüt eder. Kullanıcının
        işbu madde hükmüne aykırı fiilleri halinde Harpazar sözleşmeyi herhangi
        bir tazminat ödeme sorumluluğu olmaksızın haklı nedenle feshetme hakkına
        sahip olacak ve Harpazar'ın sair tazminat hakları saklı kalacaktır.
      </p>

      <p>
        Kullanıcı, firmanın vermiş olduğu hizmetin bir entegrasyon hizmeti
        olduğunu, sunmuş olduğu internet çözümlerinde meydana gelebilecek
        yazılımsal sorunlardan dolayı Harpazar’a sorumluluk atfedilemeyeceğini
        kabul ve taahhüt eder.
      </p>

      <p>
        Entegrasyon uygulaması dahilinde Kullanıcı stoğu güncelleme, fiyat
        güncelleme, otomatik fiyat takip özelliği, ürün başlığı bilgisi vb. gibi
        firmanın kullandığı tüm diğer ürün bilgisi araçları sayesinde
        Kullanıcıların sisteme yüklediği ürünlerin fiyatları veya stoğu ile
        ilgili otomatik olarak veya müşteri tarafından manuel
        değişiklikler/güncellemeler yapılabilmektedir. Kullanıcı hatası, eksik
        bilgi girilmesi, teknik veya yazılımsal aksaklıklar nedeniyle yaşanan
        zararlardan firma sorumlu değildir. Kullanıcı, bu nedenle oluşacak tüm
        dava ve talep haklarından gayrikabili rücu olmak üzere peşinen feragat
        eder.
      </p>

      <p>
        Kullanıcı, Web Sitesine üye olduğu esnada bir kullanıcı adı ve şifre
        oluşturacak; Kullanıcı Paneline erişmek ve diğer bazı işlemleri
        gerçekleştirebilmek için bu bilgileri kullanacaktır. Kullanıcı dilerse
        kendi alt kullanıcıları için hesap oluşturabilir. Bu alt kullanıcılar
        için kullanıcı adı ve şifreleri Kullanıcı belirler. Kullanıcının ve alt
        kullanıcıların şifre ve kullanıcı adının güvenliği ve gizliliği tamamen
        Kullanıcının sorumluluğundadır. Kullanıcı, kendi profili veya alt
        kullanıcı profili üzerinden giriş yapılarak gerçekleştirilen işlemlerin
        yetkisiz işlem olduğundan kaynaklı olarak geçersizliğini ileri sürme
        hakkından peşinen feragat etmiştir. Bu tür işlemlerde dahi, her türlü
        sorumluluk Kullanıcının kendisine ait olacaktır.
      </p>

      <p>
        Kullanıcının veya alt kullanıcının şifresi kullanılarak e-Ticaret
        Sitelerinde Harpazar aleyhine veya zararına işlem yapılması halinde;
        Kullanıcı, bu fiillerden doğan zararlardan ve zarar gören üçüncü
        kişilere Harpazar tarafından yapılmış olan ödemelerden gayrikabili rücu
        olarak Harpazar 'a karşı sorumlu olmayı peşinen kabul, beyan ve taahhüt
        eder.
      </p>

      <p>
        Kullanıcı, kendisine ya da başka bir kişiye ait veri tabanı, kayıt veya
        rehber yaratmak, kontrol etmek, güncellemek, değiştirmek ve diğer
        Kullanıcıların kişisel bilgilerine erişmek ve kendi sistemine indirmek
        amacıyla Web Sitesini kullanmamayı kabul, beyan ve taahhüt eder.
        Kullanıcı tarafından bu madde hükmüne aykırı fiillerde bulunulması ve
        işbu sözleşme ile belirlenen kullanım sınırları dışında Web Sitesinde
        yer alan Hizmetlerin kullanılması hukuka aykırı olup; Harpazar'ın bu
        kullanımlar sebebiyle doğacak zararlara karşı talep, dava ve takip
        hakları saklıdır.
      </p>

      <p>
        Kullanıcı, Web Sitesinde yürüttüğü tüm faaliyetlerini Web Sitesine
        teknik olarak hiçbir surette zarar vermeyecek şekilde yürütmekle
        yükümlüdür. Kullanıcı, Web Sitesine sağlayacağı tüm bilgi, içerik,
        materyal ve sair içeriğin sisteme zarar verecek her türlü program,
        virüs, yazılım, lisansız ürün, Truva atı, vb. içermemesi için gerekli
        her türlü tedbiri ( gerekli koruyucu yazılımlarını ve lisanslı ürünleri
        kullanmak da dâhil olmak üzere) aldığını, bu madde hükmünün uygulanması
        amacıyla Harpazar tarafından talep edilen önlemleri derhal almayı kabul,
        beyan ve taahhüt eder.
      </p>

      <p>
        Kullanıcı, işbu Sözleşmede belirtilen edinimlerini tam ve gereği gibi
        yerine getirmesi koşuluna bağlı olarak Hizmetlerden faydalanabilecektir.
        Kullanıcın bu hizmetlerden faydalanmak için Harpazar'a ödeyeceği ücret
        Web Sitesinde yer alan paket fiyat listesi ile belirlenecektir.
        Kullanıcının paket satın aldığı tarihteki fiyatlar paket süresinin
        sonuna kadar geçerlidir. Bu süre içinde veya sonunda Harpazar paket
        fiyatlarında ve paket içeriğinde değişiklik yapma hakkına sahiptir.
        Ancak kullanıcı paket süresi sonuna kadar mevcut paketi için ek bir
        ödeme yapmayacaktır. Paket süresi sonunda üyelik yenilemek ya da yeni
        paket satın almak isteyen Kullanıcı o tarihteki güncel paket fiyat
        listesindeki ücreti ödeyecektir.
      </p>

      <p>
        Kullanıcı, Web Sitesi aracılığıyla e-Ticaret Siteleri üzerinde
        yayınladığı tüm ilanlarla ve içeriklerle bağlıdır ve bunlardan
        kaynaklanan tüm taahhütlerinden sorumlu olacaktır.
      </p>

      <p>
        Harpazar, Kullanıcının faaliyetlerinin işbu Sözleşme koşullarına ve Web
        Sitesinde beyan edilen kural ve şartlara uymaması, ahlaka aykırı olması,
        mevzuata aykırılık teşkil etmesi, hukuki, teknik ve özellikle bilgi
        güvenliği anlamında risk oluşturduğunun tespit edilmesi, üçüncü
        kişilerin şahsi ve ticari haklarına halel getirici mahiyette olması
        sebebiyle, Kullanıcıya herhangi bir ön ihtarda bulunmadan ilgili
        ilanların yayınını geçici veya sürekli olarak durdurabilir, üyeliği
        askıya alabilir veya üyeliğe son verebilir. Kullanıcı böyle bir durumda
        Harpazar 'dan herhangi bir ücret ve sair tazminat talep etmemeyi kabul,
        beyan ve taahhüt eder. İşbu madde hükmüne aykırılık halinde, Harpazar'ın
        herhangi bir sebep belirtmeksizin veya ihtarda bulunmaksızın Sözleşmeyi
        istediği zaman tek taraflı fesih haklı saklıdır.
      </p>

      <p>
        Kullanıcı, almış olduğu hizmet paketlerini, Web Sitesine hiç
        girmediğinden bahisle ücret iadesi isteyemez. Harpazar, üyenin erişimine
        izin vermek ve taleplerini gerçekleştirmek suretiyle yükümlü olduğu
        hizmeti vermiş sayılır. Kullanıcının, Harpazar tarafından herhangi bir
        sebeple üyeliğinin askıya alınması veya üyelikten çıkarılarak bir daha
        Harpazar 'a platformlarına alınmamak üzere kara listeye alınması halinde
        Kullanıcı, başka bir kullanıcı hesabıyla Web Sitesine üye olamaz.
        Kullanıcının bu madde hükmüne aykırı olarak Web Sitesine üye olması ve
        durumun tespiti halinde Kullanıcı üyelikten yeniden çıkartılacak ve söz
        konusu olabilecek her türlü zararın tazmininden sorumlu olacaktır.
      </p>

      <p>
        Harpazar tarafından Web Sitesinde yapılan değişiklikler ve hizmet
        bedelleri ile ilgili değişiklikler yayınlanma tarihinde yürürlüğe
        girecek ve bağlayıcı olacaktır. Kullanıcı işbu değişikleri Web
        Sitesinden takip etmek ve değişikliklerin gereklerini yerine getirmekle
        yükümlüdür.
      </p>

      <h3><b>7.Süre</b></h3>

      <p>
        Kullanıcının kayıt işlemini tamamlanması ile birlikte seçeceği paket
        hizmet süresi boyunca aktif olacak hesap kullanıma açılır. Seçilen
        paketteki hizmet süresi sonlanıncaya dek üyelik aktif olarak
        kullanılabilir. Bu süre sonunda kullanıcı, aboneliğini yeni paket
        seçerek yenilemediği veya başka bir pakete geçiş yapmadığı takdirde
        üyeliği pasif konuma alınır. Mevcut abonelik yenilendikçe, yeni paket
        satın alındıkça ve işbu sözleşme şartlarına uyulduğu sürece üyelik aktif
        biçimde devam eder. Sözleşme koşullarına uyulmaması durumunda Harpazar
        sözleşmeyi tek taraflı feshetme hakkına sahiptir. Ücretsiz üyeliklerde
        Kullanıcının 5 gün boyunca Web Sitesine giriş yapmaması durumunda
        Harpazar Kullanıcının üyeliğini pasif duruma alma hakkını saklı tutar.
      </p>

      <h3><b>8.Hizmet Bedelleri</b></h3>

      <p>
        Harpazar tarafından sunulan hizmet paketlerinin bedelleri Web Sitesinde
        yayınlanmaktadır. Harpazar hizmet bedellerini ve hizmet içeriğini
        dilediği zaman Web Sitesinde yayınlamak koşulu ile değiştirebilir.
        Üyeliği aktif durumda olan kullanıcılar aktif paketlerinin süresi dolana
        dek bu değişikliklerden etkilenmezler. Ancak yeni paket alma ya da
        mevcut paketi yenilemeleri durumunda güncel hizmet bedelleri ve hizmet
        içeriği geçerli olur. Harpazar’ın yeni içerik, işlev ve eklentiler için
        paket satüsüne göre ek ücret talep etme hakkını saklı tuttuğunu
        kullanıcı kabul ve beyan eder.
      </p>

      <h3><b>9.Fikri Mülkiyet</b></h3>

      <p>
        Web sitesinin tasarımı, web sitesi kullanılarak hazırlanan tüm resimler,
        html kodları, içerikler, Harpazar logosu ve markası Harpazar 'a aittir.
        Kullanıcılar, Harpazar 'ın fikri mülkiyet haklarına tabi eserlerini
        kullanamaz, paylaşamaz, dağıtamaz, sergileyemez, çoğaltamaz, bunlardan
        türemiş çalışmalar yapamaz.
      </p>

      <h3><b>10.Fesih</b></h3>

      <p>
        Taraflardan herhangi biri, işbu sözleşme ve eklerini tek taraflı olarak
        feshedebilir. Kullanıcının, sözleşme süresi sona ermeden sözleşmeyi tek
        taraflı feshetmesi durumunda Harpazar, para iadesi yapmayacaktır.
        Kullanıcı bu durumu peşinen kabul eder. Harpazar, Kullanıcının işbu
        Sözleşme ve eklerini ihlal etmesi durumunda sözleşmeyi ve üyeliği derhal
        tek taraflı olarak feshedebilecektir. Bu durumda Kullanıcı, Harpazar'ın
        uğradığı/uğrayabileceği tüm zarar ve ziyandan sorumlu olduğunu peşinen
        kabul, beyan ve taahhüt eder.
      </p>

      <h3><b>11. Mücbir Sebepler</b></h3>

      <p>
        Tarafların kontrolünde olmayan; tabii afetler, salgın hastalıklar,
        yangın, patlamalar, iç savaş, savaşlar, halk hareketleri, seferberlik
        ilanı, grev, lokavt, altyapı ve internet arızaları, elektrik kesintisi,
        gibi sebeplerden dolayı sözleşmeden doğan yükümlülükler taraflarca ifa
        edilemez hale gelirse, taraflar bundan sorumlu tutulmayacaktır. Bu
        sürede tarafların bu sözleşmeden doğan hak ve yükümlülükleri askıya
        alınır.
      </p>

      <h3><b>12. İhtilaflar</b></h3>

      <p>
        İşbu sözleşmenin yorumlanmasında ve uygulanmasında Türk Hukuku geçerli
        olacaktır. İşbu Sözleşmeden doğabilecek her türlü ihtilafın hallinde
        Kayseri Mahkemeleri ve İcra Daireleri yetkilidir. Kullanıcı, işbu
        Sözleşmeden doğabilecek ihtilaflarda Harpazar'ın resmi defter ve ticari
        kayıtlarıyla, kendi veri tabanında, sunucularında tuttuğu elektronik
        bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin ve münhasır
        delil teşkil edeceğini ve bu maddenin Hukuk Muhakemeleri Kanunu'nun 193.
        maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve
        taahhüt eder.
      </p>

      <h3><b>13. Bildirim</b></h3>

      <p>
        Harpazar, Kullanıcı ile Kullanıcının Web Sitesine üye olurken bildirdiği
        e-posta adresi veya cep telefonu vasıtasıyla iletişim kuracaktır.
        Kullanıcı, Harpazar ile Web Sitesindeki Destek/İletişim sayfasını
        kullanarak iletişim kuracaktır. Harpazar maillere ve telefonlara en geç
        48 saat içerisinde dönüş yapacaktır. Bu süre, iş yoğunluğuna göre
        değişiklik gösterebilecektir. Kanunda belirtilen zorunlu haller istisna
        olup, Kullanıcının Web Sitesine kayıtlı adresine e-posta gönderimi
        yazılı iletişim olarak kabul edilecek, Kullanıcının e-posta adresini
        düzenli olarak güncel tutma yükümlülüğü olacaktır.
      </p>
    </div>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ResultDto } from 'src/app/core/dtos/api';
import { TrendyolAutoPricingLogResultDto } from 'src/app/core/dtos/trendyol-auto-pricing';
import { TrendyolAutoPricingService } from 'src/app/core/services/auto-pricing';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-trendyol-auto-pricing-logs',
  templateUrl: './trendyol-auto-pricing-logs.component.html',
  styleUrls: ['./trendyol-auto-pricing-logs.component.scss'],
})
export class TrendyolAutoPricingLogsComponent implements OnInit {
  @Input() trendyolAutoPricingId: string;
  trendyolAutoPricingLogDto: TrendyolAutoPricingLogResultDto[];
  constructor(
    private trendyolAutoPricingService: TrendyolAutoPricingService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.trendyolAutoPricingLogs(this.trendyolAutoPricingId);
  }

  trendyolAutoPricingLogs(id: string) {
    this.trendyolAutoPricingService.trendyolAutoPricingLogs(id).subscribe(
      (data: ResultDto<TrendyolAutoPricingLogResultDto[]>) => {
        if (data.success) {
          this.trendyolAutoPricingLogDto = data.data;
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }
}

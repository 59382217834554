import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResultDto } from 'src/app/core/dtos/api';
import {
  UpdateProductRequestDto,
  UpdateProductResultDto,
} from 'src/app/core/dtos/product';
import { AlertDialogService } from 'src/app/core/services/alert-dialog';
import { ProductService } from 'src/app/core/services/customer';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-update-product-stock',
  templateUrl: './form-update-product-stock.component.html',
  styleUrls: ['./form-update-product-stock.component.scss'],
})
export class FormUpdateProductStockComponent implements OnInit, AfterViewInit {
  @Input() productId: string;
  updateForm: UntypedFormGroup;
  submitted: boolean = false;
  updateProduct: UpdateProductResultDto;
  loading: boolean = false;
  doStoreDefault: boolean = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private messageService: MessageService,
    private productService: ProductService,
    private alertDialogService: AlertDialogService
  ) {
    //modal settings
    config.backdrop = 'static';
    config.keyboard = false;

    this.updateForm = this.formBuilder.group({
      doStore: [true, [Validators.required]],
      stockCount: [null, [Validators.required]],
      stockType: [null, [Validators.required]],
      stockChangeType: [null, [Validators.required]],
      stockChangeSubType: [null, [Validators.required]],
      description: [null, [Validators.required]],
      productVarianterId: [null, []],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.productByProductId();
  }

  update(postData: any) {
    this.submitted = true;
    if (this.updateForm.invalid) {
      this.messageService.error(
        'Zorunlu alanları doldurunuz.',
        'Boş geçilemez'
      );
      return;
    }

    this.alertDialogService
      .info(
        'Ürün stok sayısı sistemde ve seçmişseniz pazaryerlerinde güncellenecektir. \n Devam Edilsin mi?'
      )
      .then((result: any) => {
        if (result.isConfirmed) {
          this.updateFunc(postData);
        }
      });
  }

  updateFunc(postData: any) {
    var request = new UpdateProductRequestDto(
      this.productId,
      postData.doStore,
      postData.stockCount,
      postData.stockType,
      postData.stockChangeType,
      postData.stockChangeSubType,
      postData.description,
      postData.productVarianterId
    );

    this.loading = true;

    this.productService.updateProductStock(request).subscribe(
      (data: ResultDto<string>) => {
        if (data.success) {
          this.messageService.success(data.message);
          this.closeModal();
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  productByProductId() {
    this.productService.customerProductByProductId(this.productId).subscribe(
      (data: ResultDto<UpdateProductResultDto>) => {
        if (data.success) {
          this.updateProduct = data.data;
          if (this.updateProduct.productVarianters.length > 0) {
            this.updateForm.controls["productVarianterId"].addValidators([Validators.required])
            this.updateForm.controls["productVarianterId"].updateValueAndValidity();
          }
          else{
            this.updateForm.controls["productVarianterId"].removeValidators([Validators.required])
            this.updateForm.controls["productVarianterId"].updateValueAndValidity();
          }
          this.fillForm();
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  fillForm() {}

  closeModal() {
    this.modalService.dismissAll();
    //this.selectedAutoPricingTrendyolProductId = undefined;
  }
}

export class CreateAutoPricingRequestDto {
  constructor(
    operationId: number,
    changeStepType: number,
    minLimitPercent: number,
    actived: boolean,
    storeId?: string,
    brandId?: string,
    categoryId?: string,
    minSellerScore?: number,
    changeStepPercent?: number,
    changeStepAmount?: number,
    finishedDate?: string
  ) {
    this.operationId = operationId;
    this.changeStepType = changeStepType;
    this.minLimitPercent = minLimitPercent;
    this.actived = actived;
    this.storeId=storeId;
    this.brandId=brandId;
    this.categoryId=categoryId;
    this.minSellerScore = minSellerScore;
    this.changeStepPercent = changeStepPercent;
    this.changeStepAmount = changeStepAmount;
    this.finishedDate = finishedDate;
  }
  operationId: number;
  changeStepType: number;
  minLimitPercent: number;
  actived: boolean;
  storeId?: string;
  brandId?: string;
  categoryId?: string;
  minSellerScore?: number;
  changeStepPercent?: number;
  changeStepAmount?: number;
  finishedDate?: string;
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { PaginationResponseDto, ResultDto, SearchDto } from "../../dtos/api";
import { CreateTagRequestDto, CreateTagResultDto, DeleteTagResultDto, TagResultDto, UpdateTagRequestDto, UpdateTagResultDto } from "../../dtos/tag";
import { API_ROUTER_UTILS } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class TagService {
  constructor(private apiService: ApiService) {}

  search(
    searchDto: SearchDto
  ): Observable<ResultDto<PaginationResponseDto<TagResultDto[]>>> {
    return this.apiService.post<
      ResultDto<PaginationResponseDto<TagResultDto[]>>
    >(API_ROUTER_UTILS.url.tags.search, searchDto, null);
  }

  create(
    createTagRequestDto: CreateTagRequestDto
  ): Observable<ResultDto<CreateTagResultDto>> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.tags.create,
      createTagRequestDto,
      null
    );
  }

  update(
    updateTagRequestDto: UpdateTagRequestDto
  ): Observable<ResultDto<UpdateTagResultDto>> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.tags.update,
      updateTagRequestDto,
      null
    );
  }

  delete(tagId: string): Observable<ResultDto<DeleteTagResultDto>> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.tags.delete,
      null,
      null,
      { tagId: tagId }
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ResultDto } from 'src/app/core/dtos/api';
import { BrandResultDto } from 'src/app/core/dtos/trendyol';
import { MessageService } from 'src/app/core/services/message';
import { TrendyolService } from 'src/app/core/services/trendyol';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-trendyol-brands',
  templateUrl: './form-select-trendyol-brands.component.html',
  styleUrls: ['./form-select-trendyol-brands.component.scss']
})
export class FormSelectTrendyolBrandsComponent implements OnInit {
  @Input() brandForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Output() changeBrandEmit = new EventEmitter<string | undefined>();

  brands: BrandResultDto[] = [];
  constructor(
    private trendyolService: TrendyolService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  searchBrands(searchKey: string) {
    this.trendyolService.searchBrands(searchKey).subscribe(
      (data: ResultDto<BrandResultDto[]>) => {
        this.brands = data.data;
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  onSearch($event: any) {
    if ($event.term.length >= 3) {
      this.searchBrands($event.term);
    }
  }

  onChange($event:any) {
    if ($event != undefined) {
      let brandName = this.brands.filter((element) => element.id == $event)[0]
        .name;
      this.changeBrandEmit.emit(brandName);
    } else {
      this.changeBrandEmit.emit(undefined);
    }
  }

}

export class CreateTrendyolAutoPricingRequestDto {
  constructor(
    trendyolProductId: string,
    minPrice:number,
    changeAmount:number,
    actived: boolean,
    minSellerScore?: number
  ) {
    this.trendyolProductId = trendyolProductId;
    this.minPrice=minPrice;
    this.changeAmount=changeAmount;
    this.actived=actived;
    this.minSellerScore = minSellerScore;
  }
  trendyolProductId: string;
  minPrice:number;
  changeAmount:number;
  actived: boolean;
  minSellerScore?: number;
}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-auto-pricing-change-step-amount',
  templateUrl: './auto-pricing-change-step-amount.component.html',
  styleUrls: ['./auto-pricing-change-step-amount.component.scss']
})
export class AutoPricingChangeStepAmountComponent implements OnInit {
  @Input() autoPricingForm: UntypedFormGroup;
  @Input() required?: boolean;
  @Input() submitted: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ResultDto } from 'src/app/core/dtos/api';
import { StoreResultDto } from 'src/app/core/dtos/store';
import { CommonStoreService } from 'src/app/core/services/customer';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-stores',
  templateUrl: './form-select-stores.component.html',
  styleUrls: ['./form-select-stores.component.scss']
})
export class FormSelectStoresComponent implements OnInit {
  @Input() storeForm: UntypedFormGroup;
  @Input() required?: boolean;
  @Input() submitted: boolean;
  stores: StoreResultDto[];
  constructor(
    private commonStoreService: CommonStoreService,
    private messageService: MessageService
  ) {
    this.getStores();
  }

  ngOnInit(): void {}

  getStores() {
    this.commonStoreService.customerStores().subscribe(
      (data: ResultDto<StoreResultDto[]>) => {
        if (data.success) {
          this.stores = data.data;
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

}

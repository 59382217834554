import { FormArray, FormGroup } from '@angular/forms';

// form submit olduğunda hatalı kontrolleri göstermek için
export function markFormGroupTouched(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control) {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        markFormGroupTouched(control); // İç içe geçmiş form grupları için özyinelemeli çağrı
      }

      if (control instanceof FormArray) {
        // Eğer form kontrolü bir FormArray ise
        for (let i = 0; i < control.length; i++) {
          if (control.at(i) instanceof FormGroup) {
            markFormGroupTouched(control.at(i) as FormGroup); // FormArray içindeki FormGroup'ları işle
          }
        }
      }
    }
  });
}

<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="" />
  <div class="media-body">
    <span *ngIf="userInfoModel != undefined"
      >{{ userInfoModel.firstName }} {{ userInfoModel.lastName }}</span
    >
    <p class="mb-0 font-roboto">
      Kullanıcı <i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <a (click)="profile()"
      ><app-feather-icons [icon]="'users'"></app-feather-icons
      ><span>Profil</span></a
    >
  </li>
  <li *ngIf="authService.hasRole('CustomerAdmin')">
    <a (click)="openFirmInfo()"
      ><app-feather-icons [icon]="'home'"></app-feather-icons
      ><span>Firma Bilgileri</span></a
    >
  </li>
  <li>
    <a (click)="signOut()"
      ><i class="fa fa-sign-out"></i><span>Çıkış Yap</span></a
    >
  </li>
</ul>

<div class="form-group m-form__group" [formGroup]="autoPricingForm">
  <div
    class="input-group"
    [ngClass]="{
      'input-error': autoPricingForm.get('minLimitPercent').invalid && submitted
    }"
  >
    <div class="input-group-prepend">
      <span
        class="input-group-text"
        [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
      >
        <i
          class="fa fa-info-circle info-color"
          ngbTooltip="Ürün ilan fiyatı üzerinden uygulanacak maksimum yüzdeliktir. Örneğin; 100 ₺ olan ürününüz max. %5'lik düşecek ise 95₺ ye kadar otomatik fiyatlandırma yapılır. Buna uygun şekilde 'Değişim Tutarı' ve 'Değişim yüzdesi giriniz.'"
        ></i
        >Yüzde Alt Sınır Fiyat <span class="required-input">*</span></span
      >
    </div>
    <input
      class="form-control"
      [type]="'text'"
      formControlName="minLimitPercent"
      onlyNumbers
      [max]="50"
      [min]="1"
    />
  </div>
</div>

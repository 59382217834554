import { Injectable } from '@angular/core';
import { MessageService } from '../message';
import { HttpErrorResponse } from '@angular/common/http';
import { httpErrorResponseUtil } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ApiHandleService {
  constructor(private messageService: MessageService) {}

  handleError(error: HttpErrorResponse) {
    var errorStr = httpErrorResponseUtil(error);
    this.messageService.error(errorStr);
  }
}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import { API_ROUTER_UTILS } from '../../utils';
import { CreateHepsiBuradaAccountRequestDto, CreateHepsiBuradaProductRequestDto } from '../../dtos/hepsiburada';

@Injectable({
  providedIn: 'root',
})
export class CustomerHepsiBuradaService {
  constructor(private apiService: ApiService) {}

  createHepsiBuradaStore(
    data: CreateHepsiBuradaAccountRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.integration.createHepsiBuradaStore,
      data,
      null,
      {}
    );
  }

  createProduct(
    createProductRequest: CreateHepsiBuradaProductRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.customerHepsiBurada.createProduct,
      createProductRequest,
      null
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { PaginationResponseDto, ResultDto, SearchDto } from '../../dtos/api';
import {
  CreateCustomerCustomerRequestDto,
  CustomerCustomerResultDto,
  CustomerPaginationReponseDto,
} from '../../dtos/customer';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private apiService: ApiService) {}

  getCustomers(search: SearchDto): Observable<CustomerPaginationReponseDto> {
    return this.apiService.post<CustomerPaginationReponseDto>(
      API_ROUTER_UTILS.url.customers.search,
      search,
      null,
      {}
    );
  }

  customerCustomers(): Observable<ResultDto<CustomerCustomerResultDto[]>> {
    return this.apiService.get<ResultDto<CustomerCustomerResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.customers.customerCustomers,
      null
    );
  }

  customerCustomerAllSearch(
    searchKey: string
  ): Observable<ResultDto<CustomerCustomerResultDto[]>> {
    return this.apiService.get<ResultDto<CustomerCustomerResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.customers.customerCustomerAllSearch,
      { searchKey: searchKey }
    );
  }

  customerCustomerSearch(
    searchDto: SearchDto
  ): Observable<ResultDto<PaginationResponseDto<CustomerCustomerResultDto[]>>> {
    return this.apiService.post<
      ResultDto<PaginationResponseDto<CustomerCustomerResultDto[]>>
    >(API_ROUTER_UTILS.url.customers.customerCustomerSearch, searchDto, null);
  }

  createCustomerCustomer(request: CreateCustomerCustomerRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.customers.createCustomerCustomer,
      request,
      null,
      {}
    );
  }
}

import { ProductVarianterRequestDto } from '../product-varianter';
import { ProductFileUploadRequestDto } from '../product-image/product-file-upload-request.dto';

export class CreateProductRequestDto {
  constructor(
    categoryId: string,
    brandId: string,
    productName: string,
    foreignCurrencyId: string,
    vatId: string,
    productFileUploadRequests: ProductFileUploadRequestDto[],
    varianters: ProductVarianterRequestDto[],
    price: number | null,
    gtin: string | null,
    mpn: string | null,
    description: string | null,
    barcode: string | null,
    stockCode: string | null,
    stockCount: number | null
  ) {
    this.categoryId = categoryId;
    this.brandId = brandId;
    this.productName = productName;
    this.price = price;
    this.stockCount = stockCount;
    this.barcode = barcode;
    this.stockCode = stockCode;
    this.foreignCurrencyId = foreignCurrencyId;
    this.vatId = vatId;
    this.productFileUploadRequests = productFileUploadRequests;
    this.varianters = varianters;
    this.gtin = gtin;
    this.mpn = mpn;
    this.description = description;
  }

  categoryId: string;
  brandId: string;
  productName: string;
  price: number | null;
  stockCount: number | null;
  barcode: string | null;
  stockCode?: string | null;
  foreignCurrencyId: string;
  vatId: string;
  productFileUploadRequests: ProductFileUploadRequestDto[];
  varianters: ProductVarianterRequestDto[];
  gtin: string | null;
  mpn: string | null;
  description: string | null;
}

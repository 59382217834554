<h6>Paket Bölme</h6>
<hr />
<div class="row">
  <div class="col-md-3">
    <button
      type="button"
      class="btn btn-outline-success btn-sm"
      (click)="newPackage()"
      *ngIf="!addPackageShow"
    >
      Yeni Paket Oluştur
    </button>
  </div>
  <div class="col-md-4">
    <button
      type="button"
      class="btn btn-outline-danger btn-sm"
      (click)="deleteAllPackage()"
      *ngIf="isPacketsGroupShow.controls.length > 0"
    >
      Paketleri Kaldır
    </button>
  </div>
  <form
    *ngIf="addPackageShow"
    class="theme-form"
    [formGroup]="addPackageForm"
    (ngSubmit)="addPackage()"
    id="addPackageSubmitForm"
  >
    <div formArrayName="packets">
      <ng-container [formGroupName]="packageIndex">
        <ng-container formArrayName="packetItems">
          <div
            class="row"
            *ngFor="
              let item of packagesPreviewResult.lines;
              let lineIndex = index
            "
            [formGroupName]="lineIndex"
          >
            <input
              type="hidden"
              formControlName="stringOrderLineId"
              [value]="item.stringOrderLineId"
            />
            <input type="hidden" formControlName="longOrderLineId" />
            <div class="col-md-1">
              <mat-slide-toggle
                color="primary"
                formControlName="isLineChecked"
              ></mat-slide-toggle>
            </div>
            <div class="col-md-9">
              <mat-form-field appearance="outline">
                <mat-label>Ürün</mat-label>
                <input readonly matInput formControlName="productName" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="outline">
                <mat-label>Adet</mat-label>
                <input onlyNumbers matInput formControlName="quantity" />
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>

  <div class="row" *ngIf="addPackageShow">
    <div class="col-md-3">
      <button
        type="submit"
        class="btn btn-outline-success btn-sm"
        form="addPackageSubmitForm"
        [disabled]="addPackageForm.invalid"
      >
        Paket Ekle
      </button>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-3"
      *ngFor="let group of isPacketsGroupShow.controls; let i = index"
    >
      <div>
        <b>{{ group.get("packetInfo").value }}</b>
      </div>
      <div class="list-group" style="border: 1px solid red">
        <a
          class="list-group-item list-group-item-action flex-column align-items-start"
        >
          <div class="row">
            <div
              class="col-md-6"
              *ngFor="let subGroup of isPacketItemsGroupChecked(i)"
            >
              <div
                id="resimKapsayici"
                *ngIf="subGroup.get('productImageBase64Url').value != null"
              >
                <img
                  [src]="subGroup.get('productImageBase64Url').value"
                  ngbTooltip="{{ subGroup.get('productName').value }}"
                  alt="Ürün Resmi"
                />
                <div id="adetidini">x{{ subGroup.get("quantity").value }}</div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <hr style="margin-top: 10px" />
  <div class="row">
    <div class="col-md-12">
      <h6>Sipariş Kalemler</h6>
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Ürün</th>
            <th>Adet</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of packagesPreviewResult.lines">
            <td>
              <img
                [src]="item.productImageBase64Url"
                alt="Ürün Resmi"
                *ngIf="item.productImageBase64Url != null"
                width="60"
                height="60"
              />
            </td>
            <td>{{ item.productName }}</td>
            <td>{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <hr />
  <div class="custom-material-dialog-footer">
    <button
      class="btn btn-primary btn-sm"
      (click)="outputPackage()"
      [disabled]="addPackageForm.invalid || packetsGroup.controls.length == 0"
    >
      Paketleri Ekle
    </button>
    <button class="btn btn-light btn-sm" (click)="closeDialog()">Kapat</button>
  </div>
</div>

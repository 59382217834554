import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import {
  HepsiBuradaShipmentProviderResultDto,
  HepsiBuradaStoreDetailResultDto,
  HepsiBuradaUpdateStoreRequestDto,
} from '../../dtos/hepsiburada';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class HepsiBuradaStoreService {
  constructor(private apiService: ApiService) {}

  shipmentProviders(): Observable<
    ResultDto<HepsiBuradaShipmentProviderResultDto[]>
  > {
    return this.apiService.get<
      ResultDto<HepsiBuradaShipmentProviderResultDto[]>
    >(null, API_ROUTER_UTILS.url.integration.hepsiBuradaShipmentProviders);
  }

  storeDetail(
    storeId: string
  ): Observable<ResultDto<HepsiBuradaStoreDetailResultDto>> {
    return this.apiService.get<ResultDto<HepsiBuradaStoreDetailResultDto>>(
      null,
      API_ROUTER_UTILS.url.integration.hepsiBuradaStoreDetail,
      { storeId: storeId }
    );
  }

  postUpdateUpdateStore(
    request: HepsiBuradaUpdateStoreRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.integration.hepsiBuradaUpdateStore,
      request,
      null
    );
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { PaginationResponseDto, ResultDto, SearchDto } from "../../dtos/api";
import { AnswerQuestionRequestDto, QuestionFunctionResultDto } from "../../dtos/question";
import { API_ROUTER_UTILS } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class QuestionService {
  constructor(private apiService: ApiService) {}

  search(searchDto: SearchDto): Observable<ResultDto<PaginationResponseDto<QuestionFunctionResultDto[]>>> {
    return this.apiService.post<ResultDto<PaginationResponseDto<QuestionFunctionResultDto[]>>>(
      API_ROUTER_UTILS.url.questions.search,
      searchDto,
      null
    );
  }
}

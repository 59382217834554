import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavService } from './services/nav.service';
import { FullComponent } from './layout/full/full.component';
import { ContentComponent } from './layout/content/content.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import { FormSelectBrandsComponent } from './components/form-select-brands/form-select-brands.component';
import { FormSelectCategoriesComponent } from './components/form-select-categories/form-select-categories.component';
import { FormSelectVariantersComponent } from './components/form-select-varianters/form-select-varianters.component';
import { FormSelectCurrencyTypesComponent } from './components/form-select-currency-types/form-select-currency-types.component';
import { FormSelectVatsComponent } from './components/form-select-vats/form-select-vats.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { StockTransactionByProductIdComponent } from './components/stock-transaction-by-product-id/stock-transaction-by-product-id.component';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormSelectTrendyolCategoriesComponent } from './components/form-select-trendyol-categories/form-select-trendyol-categories.component';
import { FormSelectTrendyolBrandsComponent } from './components/form-select-trendyol-brands/form-select-trendyol-brands.component';
import { FormSelectStoresComponent } from './components/form-select-stores/form-select-stores.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { CurrencyInputDirective } from './directives/currency-input.directive';
import { OnlyDecimalInputDirective } from './directives/only-decimal-input.directive';
import { LayoutService } from './services/layout.service';
import { TrendyolOtherSellersComponent } from './components/trendyol-other-sellers/trendyol-other-sellers.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AutoPricingOperationTypeComponent } from './components/auto-pricing/auto-pricing-operation-type/auto-pricing-operation-type.component';
import { AutoPricingChangeStepAmountComponent } from './components/auto-pricing/auto-pricing-change-step-amount/auto-pricing-change-step-amount.component';
import { AutoPricingChangeStepPercentComponent } from './components/auto-pricing/auto-pricing-change-step-percent/auto-pricing-change-step-percent.component';
import { AutoPricingMinSellerScoreComponent } from './components/auto-pricing/auto-pricing-min-seller-score/auto-pricing-min-seller-score.component';
import { AutoPricingFinishedDateComponent } from './components/auto-pricing/auto-pricing-finished-date/auto-pricing-finished-date.component';
import { AutoPricingMinLimitFromSalePriceComponent } from './components/auto-pricing/auto-pricing-min-limit-from-sale-price/auto-pricing-min-limit-from-sale-price.component';
import { AutoPricingChangeStepTypeComponent } from './components/auto-pricing/auto-pricing-change-step-type/auto-pricing-change-step-type.component';
import { AutoPricingActivedComponent } from './components/auto-pricing/auto-pricing-actived/auto-pricing-actived.component';
import { TrendyolAutoPricingLogsComponent } from './components/trendyol-auto-pricing-logs/trendyol-auto-pricing-logs.component';
import { FormSelectHepsiBuradaCategoriesComponent } from './components/hepsiburada/form-select-hepsi-burada-categories/form-select-hepsi-burada-categories.component';
import { FormSelectHepsiBuradaBrandsComponent } from './components/hepsiburada/form-select-hepsi-burada-brands/form-select-hepsi-burada-brands.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { FormCustomerCustomerComponent } from './components/form-customer-customer/form-customer-customer.component';
import { FormUpdateProductStockComponent } from './components/form-update-product-stock/form-update-product-stock.component';
import { PriceFormatDirective } from './directives/price-format.directive';
import { HepsiburadaOtherSellersComponent } from './components/hepsiburada-other-sellers/hepsiburada-other-sellers.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ImageShowComponent } from './components/image-show/image-show.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SalePackagesComponent } from './components/sale-packages/sale-packages.component';
import {MatSliderModule} from '@angular/material/slider';
import { SalePackagesSplitComponent } from './components/sale-packages-split/sale-packages-split.component';
import { TrendyolAutoPricingDetailComponent } from './components/trendyol-auto-pricing-detail/trendyol-auto-pricing-detail.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { TrendyolAutoPricingComponent } from '../pages/product/trendyol/trendyol-auto-pricing/trendyol-auto-pricing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { GeneralUserAgreementComponent } from './components/general-user-agreement/general-user-agreement.component';
import { GeneralUserAgreementDialogComponent } from './components/general-user-agreement-dialog/general-user-agreement-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FullComponent,
    ContentComponent,
    SidebarComponent,
    FooterComponent,
    FeatherIconsComponent,
    MyAccountComponent,
    FormSelectBrandsComponent,
    FormSelectCategoriesComponent,
    FormSelectVariantersComponent,
    FormSelectCurrencyTypesComponent,
    FormSelectVatsComponent,
    BreadcrumbComponent,
    StockTransactionByProductIdComponent,
    FormSelectTrendyolCategoriesComponent,
    FormSelectTrendyolBrandsComponent,
    FormSelectStoresComponent,
    OnlyNumbersDirective,
    CurrencyInputDirective,
    OnlyDecimalInputDirective,
    PriceFormatDirective,
    TrendyolOtherSellersComponent,
    LoadingComponent,
    AutoPricingOperationTypeComponent,
    AutoPricingChangeStepAmountComponent,
    AutoPricingChangeStepPercentComponent,
    AutoPricingMinSellerScoreComponent,
    AutoPricingFinishedDateComponent,
    AutoPricingMinLimitFromSalePriceComponent,
    AutoPricingChangeStepTypeComponent,
    AutoPricingActivedComponent,
    TrendyolAutoPricingLogsComponent,
    FormSelectHepsiBuradaCategoriesComponent,
    FormSelectHepsiBuradaBrandsComponent,
    CustomizerComponent,
    FormCustomerCustomerComponent,
    FormUpdateProductStockComponent,
    HepsiburadaOtherSellersComponent,
    ImageShowComponent,
    SalePackagesComponent,
    SalePackagesSplitComponent,
    TrendyolAutoPricingDetailComponent,
    InfoDialogComponent,
    TrendyolAutoPricingComponent,
    NotFoundComponent,
    GeneralUserAgreementComponent,
    GeneralUserAgreementDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    NgSelectModule,
    MatTabsModule,
    NgbModule,
    NgbAlertModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'Kayıt bulunamadı',
        totalMessage: 'adet toplam kayıt',
        selectedMessage: 'adet kayıt seçildi',
      },
    }),
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatRadioModule,
    MatSliderModule,
    NgxSpinnerModule.forRoot({ type: 'square-jelly-box' }),
  ],
  providers: [NavService, LayoutService, CurrencyInputDirective],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    MatTabsModule,
    NgbAlertModule,
    NgxDatatableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatDialogModule,
    NgxSpinnerModule,
    DragDropModule,
    FormSelectBrandsComponent,
    FormSelectCategoriesComponent,
    FormSelectVariantersComponent,
    FormSelectCurrencyTypesComponent,
    FormSelectVatsComponent,
    BreadcrumbComponent,
    StockTransactionByProductIdComponent,
    FormSelectTrendyolCategoriesComponent,
    FormSelectTrendyolBrandsComponent,
    FormSelectStoresComponent,
    TrendyolOtherSellersComponent,
    HepsiburadaOtherSellersComponent,
    OnlyNumbersDirective,
    CurrencyInputDirective,
    OnlyDecimalInputDirective,
    PriceFormatDirective,
    LoadingComponent,
    AutoPricingOperationTypeComponent,
    AutoPricingChangeStepAmountComponent,
    AutoPricingChangeStepPercentComponent,
    AutoPricingMinSellerScoreComponent,
    AutoPricingFinishedDateComponent,
    AutoPricingMinLimitFromSalePriceComponent,
    AutoPricingChangeStepTypeComponent,
    AutoPricingActivedComponent,
    TrendyolAutoPricingLogsComponent,
    FormSelectHepsiBuradaCategoriesComponent,
    FormSelectHepsiBuradaBrandsComponent,
    FormCustomerCustomerComponent,
    FormUpdateProductStockComponent,
    ImageShowComponent,
    SalePackagesComponent,
    SalePackagesSplitComponent,
    TrendyolAutoPricingDetailComponent,
    TrendyolAutoPricingComponent,
    NotFoundComponent,
    GeneralUserAgreementComponent,
    GeneralUserAgreementDialogComponent
  ],
})
export class SharedModule {}


import {
  Component,
  Input,
  type OnInit,
} from '@angular/core';

@Component({
  selector: 'app-image-show',
  templateUrl: './image-show.component.html',
  styleUrls: ['./image-show.component.scss'],
})
export class ImageShowComponent implements OnInit {
  @Input() imageBase64Url?: string;
  @Input() imageHttpUrl?: string;
  constructor() {}
  ngOnInit(): void {

  }

  closeDialog(){

  }
}

import { Injectable } from '@angular/core';
declare var require:any;
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertDialogService {
  constructor() {}

  info(title: string) {
    return Swal.fire({
      icon: 'info',
      title: title,
      showCancelButton: true,
      confirmButtonColor: 'LightSeaGreen',
      confirmButtonText: 'Tamam',
      cancelButtonText: 'Kapat',
    });
  }

  warning(title: string) {
    return Swal.fire({
      icon: 'warning',
      title: title,
      showCancelButton: true,
      confirmButtonColor: 'LightSeaGreen',
      confirmButtonText: 'Sil',
      cancelButtonText: 'Kapat',
    });
  }

  warningCustom(title: string, confirmButtonText: string) {
    return Swal.fire({
      icon: 'warning',
      title: title,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor:'grey',
      confirmButtonText: confirmButtonText,
      cancelButtonText: 'Kapat',
    });
  }

  warningCustomDanger(title: string, confirmButtonText: string) {
    return Swal.fire({
      icon: 'warning',
      title: title,
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor:'grey',
      confirmButtonText: confirmButtonText,
      cancelButtonText: 'Kapat',
    });
  }
}

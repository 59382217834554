<div class="form-group m-form__group" [formGroup]="brandForm">
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text"
      [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >Marka <span *ngIf="required != undefined && required" class="required-input">*</span></span
      >
    </div>
    <ng-select
      class="form-control"
      placeholder="Seçiniz..."
      formControlName="brandId"
    >
      <ng-option *ngFor="let item of customerBrands" [value]="item.id">{{
        item.name
      }}</ng-option>
    </ng-select>
  </div>
</div>




import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { CurrencyCodeResultDto } from 'src/app/core/dtos/value-table';
import { MessageService } from 'src/app/core/services/message';
import { CurrencyTypeService } from 'src/app/core/services/value-table';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-currency-types',
  templateUrl: './form-select-currency-types.component.html',
  styleUrls: ['./form-select-currency-types.component.scss'],
})
export class FormSelectCurrencyTypesComponent implements OnInit {
  @Input() foreignCurrencyForm: UntypedFormGroup;
  @Input() submitted: boolean;
  foreignCurrencies: CurrencyCodeResultDto[] = [];
  constructor(
    private valueTableService: CurrencyTypeService,
    private messageService: MessageService
  ) {
    var a=this.foreignCurrencyForm;
  }

  ngOnInit(): void {
    this.getForeignCurrencies();
  }

  getForeignCurrencies() {
    this.valueTableService.foreignCurrencies().subscribe(
      (data: CurrencyCodeResultDto[]) => {
        this.foreignCurrencies = data;
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }
}

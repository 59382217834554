import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../apis';
import { ResultDto } from '../../../dtos/api';
import {
  StoreAddressResultDto,
  TrendyolStoreDetailResultDto,
  TrendyolUpdateStoreRequestDto,
} from '../../../dtos/store';
import {
  TrendyolShipmentProviderResultDto,
  TrendyolStoreInfoResultDto,
} from '../../../dtos/trendyol';
import { API_ROUTER_UTILS } from '../../../utils';

@Injectable({
  providedIn: 'root',
})
export class TrendyolStoreService {
  constructor(private apiService: ApiService) {}

  storeDetail(
    storeId: string
  ): Observable<ResultDto<TrendyolStoreDetailResultDto>> {
    return this.apiService.get<ResultDto<TrendyolStoreDetailResultDto>>(
      null,
      API_ROUTER_UTILS.url.integration.trendyolStoreDetail,
      { storeId: storeId }
    );
  }

  storeCreateOrUpdateAddresses(
    storeId: string
  ): Observable<ResultDto<StoreAddressResultDto[]>> {
    return this.apiService.get<ResultDto<StoreAddressResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.integration.trendyolCreateOrUpdateAddresses,
      { storeId: storeId }
    );
  }

  storeAddresses(
    storeId: string
  ): Observable<ResultDto<StoreAddressResultDto[]>> {
    return this.apiService.get<ResultDto<StoreAddressResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.integration.trendyolStoreAddresses,
      { storeId: storeId }
    );
  }

  shipmentProviders(): Observable<
    ResultDto<TrendyolShipmentProviderResultDto[]>
  > {
    return this.apiService.get<ResultDto<TrendyolShipmentProviderResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.integration.trendyolShipmentProviders
    );
  }

  postUpdateUpdateStore(
    request: TrendyolUpdateStoreRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.integration.trendyolUpdateStore,
      request,
      null
    );
  }

  infoByStoreId(storeId:any): Observable<ResultDto<TrendyolStoreInfoResultDto>> {
    return this.apiService.get<ResultDto<TrendyolStoreInfoResultDto>>(
      null,
      API_ROUTER_UTILS.url.integration.trendyolStoreInfo,
      { storeId: storeId }
    );
  }
}

export const MANAGEMENT_ROUTER_UTILS = {
  config: {
    customers: {
      root: 'musteriler',
      customerList: 'musteri-listesi',
      customerStores: 'musteri-magaza-listesi',
    },
    users: {
      root: 'kullanicilar',
      userList: 'kullanici-listesi',
      createUser: 'kullanici-ekle',
      userPermissions: 'kullanici-izinleri',
    },
    roles: {
      root: 'roller',
      roleList: 'rol-listesi',
      rolePermissions: 'rol-izinleri',
      createRolePermission: 'rol-izni-ekle',
    },
    version: {
      root: 'version',
      createVersion: 'yeni',
      versions: 'versiyonlar',
      updateVersion: 'duzenle',
    },
    storeSettings: {
      root:"magaza-ayarlari",
      trendyolCategory:"trendyol-kategorileri"
    },
  },
};

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private toastrService: ToastrService) {}

  success(message: string) {
    this.toastrService.success(message, null, {
      progressBar: true,
      positionClass: 'toast-top-right',
      closeButton:true,
    });
  }

  warning(message: string) {
    this.toastrService.warning(message, null, {
      progressBar: true,
      positionClass: 'toast-top-right',
      closeButton:true
    });
  }

  error(message: string, title?:string) {
    this.toastrService.error(message, title, {
      progressBar: true,
      positionClass: 'toast-top-right',
      closeButton:true
    });
  }
}

<div class="form-group m-form__group" [formGroup]="autoPricingForm">
  <div
    class="input-group"
    [ngClass]="{
      'input-error': autoPricingForm.get('finishedDate').invalid && submitted
    }"
  >
    <div class="input-group-prepend">
      <span
        class="input-group-text"
        [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >Bitiş Tarihi</span
      >
    </div>
    <input
      (click)="d.toggle()"
      class="form-control group-prepend-update"
      placeholder="yyyy-mm-dd"
      formControlName="finishedDate"
      ngbDatepicker
      #d="ngbDatepicker"
      readonly
    />
    <button
      class="btn btn-outline-secondary"
      (click)="d.toggle()"
      type="button"
    >
      <i class="fa fa-calendar"></i>
    </button>
  </div>
</div>

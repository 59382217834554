export class PackagesSplitRequestDto {
  constructor(
    storeId:string,
    storeName: string,
    storeTypeImageBase64Url: string,
    storeTypeName: string,
    orderId: string,
    packageDetails: PackagesSplitLineRequestDto[],
    barcode:string,
    stringPackageNumber?: string,
    longPackageNumber?: number,
    stringOrderNumber?: string,
    longOrderNumber?: number
  ) {
    this.storeId=storeId;
    this.storeName = storeName;
    this.storeTypeImageBase64Url = storeTypeImageBase64Url;
    this.storeTypeName = storeTypeName;
    this.barcode=barcode;
    this.stringPackageNumber = stringPackageNumber;
    this.longPackageNumber = longPackageNumber;
    this.stringOrderNumber=stringOrderNumber;
    this.longOrderNumber=longOrderNumber;
    this.orderId = orderId;
    this.packageDetails = packageDetails;
  }
  storeId:string;
  storeName: string;
  storeTypeImageBase64Url: string;
  storeTypeName: string;
  orderId: string;
  barcode:string;
  stringPackageNumber?: string;
  longPackageNumber?: number;
  stringOrderNumber?: string;
  longOrderNumber?: number;
  packageDetails: PackagesSplitLineRequestDto[];
}

export class PackagesSplitLineRequestDto {
  constructor(lines: PackageSplitItemRequestDto[]) {
    this.lines = lines;
  }
  lines: PackageSplitItemRequestDto[];
}

export class PackageSplitItemRequestDto {
  constructor(
    quantity: number,
    stringOrderLineId?: string,
    longOrderLineId?: number
  ) {
    this.quantity = quantity;
    this.stringOrderLineId = stringOrderLineId;
    this.longOrderLineId = longOrderLineId;
  }
  quantity: number;
  stringOrderLineId?: string;
  longOrderLineId?: number;
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { ResultDto, SearchDto } from "../../dtos/api";
import { API_ROUTER_UTILS } from "../../utils";
import { NewReturnSearchAndSaveResultDto, ReturnOrderLineResultDto } from "../../dtos/return";

@Injectable({
  providedIn: "root",
})
export class TrendyolReturnService {
  constructor(private apiService: ApiService) {}

  newReturns(): Observable<ResultDto<NewReturnSearchAndSaveResultDto[]>> {
    return this.apiService.get<ResultDto<NewReturnSearchAndSaveResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyolReturns.newReturn
    );
  }

  returnOrderLines(
    returnId: string,
    storeTypeName: string
  ): Observable<ResultDto<ReturnOrderLineResultDto[]>> {
    return this.apiService.get<ResultDto<ReturnOrderLineResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyolReturns.returnOrderLines,
      { returnId: returnId, storeTypeName: storeTypeName }
    );
  }

  returnClaimItems(
    returnOrderLineId: string,
    storeTypeName: string
  ): Observable<ResultDto> {
    return this.apiService.get<ResultDto>(
      null,
      API_ROUTER_UTILS.url.trendyolReturns.returnClaimItems,
      { returnOrderLineId: returnOrderLineId, storeTypeName: storeTypeName }
    );
  }
}

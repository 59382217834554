import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ResultDto } from 'src/app/core/dtos/api';
import { ProductStockTransactionResultDto } from 'src/app/core/dtos/product-stock-transaction';
import { ProductStockTransactionService } from 'src/app/core/services/customer';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-stock-transaction-by-product-id',
  templateUrl: './stock-transaction-by-product-id.component.html',
  styleUrls: ['./stock-transaction-by-product-id.component.scss']
})
export class StockTransactionByProductIdComponent implements OnInit {
  @Input() productId: string;
  customerProductStockTransactionsByProductId: ProductStockTransactionResultDto[];
  constructor(
    private messageService: MessageService,
    private productStockTransactionService: ProductStockTransactionService
  ) {}

  ngOnInit(): void {
    this.productStockTransactionByProductId();
  }

  productStockTransactionByProductId() {
    this.productStockTransactionService
      .byProductId(this.productId)
      .subscribe(
        (data: ResultDto<ProductStockTransactionResultDto[]>) => {
          if (data.success) {
            this.customerProductStockTransactionsByProductId = data.data;
          } else {
            for (let index = 0; index < data.errors.length; index++) {
              this.messageService.error(data.message[index]);
            }
          }
        },
        (error: HttpErrorResponse) => {
          var errorStr = httpErrorResponseUtil(error);
          this.messageService.error(errorStr);
        }
      );
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { PaginationResponseDto, ResultDto, SearchDto } from '../../dtos/api';
import {
  BrandResultDto,
  CreateBrandRequestDto,
  DeleteBrandResultDto,
  UpdateBrandRequestDto,
  UpdateBrandResultDto,
} from '../../dtos/brand';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  constructor(private apiService: ApiService) {}

  search(
    searchDto: SearchDto
  ): Observable<ResultDto<PaginationResponseDto<BrandResultDto[]>>> {
    return this.apiService.post<
      ResultDto<PaginationResponseDto<BrandResultDto[]>>
    >(API_ROUTER_UTILS.url.brands.search, searchDto, null);
  }

  create(createBrandRequestDto: CreateBrandRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.brands.create,
      createBrandRequestDto,
      null
    );
  }

  update(
    updateBrandRequestDto: UpdateBrandRequestDto
  ): Observable<ResultDto<UpdateBrandResultDto>> {
    return this.apiService.post<ResultDto<UpdateBrandResultDto>>(
      API_ROUTER_UTILS.url.brands.update,
      updateBrandRequestDto,
      null
    );
  }

  delete(brandId: string): Observable<ResultDto<DeleteBrandResultDto>> {
    return this.apiService.post<ResultDto<DeleteBrandResultDto>>(
      API_ROUTER_UTILS.url.brands.delete,
      null,
      null,
      { brandId: brandId }
    );
  }

  all(): Observable<BrandResultDto[]> {
    return this.apiService.get<BrandResultDto[]>(
      null,
      API_ROUTER_UTILS.url.brands.all
    );
  }
}

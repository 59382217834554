import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import {
  CategoryResultDto,
  CreateCategoryRequestDto,
  CreateCategoryResultDto,
  DeleteCategoryResultDto,
  TreeCategoryResultDto,
  UpdateCategoryRequestDto,
  UpdateCategoryResultDto,
} from '../../dtos/category';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private apiService: ApiService) {}

  create(
    createCategoryRequestDto: CreateCategoryRequestDto
  ): Observable<ResultDto<CreateCategoryResultDto>> {
    return this.apiService.post<ResultDto<CreateCategoryResultDto>>(
      API_ROUTER_UTILS.url.categories.create,
      createCategoryRequestDto,
      null
    );
  }

  update(
    updateCategoryRequestDto: UpdateCategoryRequestDto
  ): Observable<ResultDto<UpdateCategoryResultDto>> {
    return this.apiService.post<ResultDto<UpdateCategoryResultDto>>(
      API_ROUTER_UTILS.url.categories.update,
      updateCategoryRequestDto,
      null
    );
  }

  delete(categoryId: string): Observable<ResultDto<DeleteCategoryResultDto>> {
    return this.apiService.post<ResultDto<DeleteCategoryResultDto>>(
      API_ROUTER_UTILS.url.categories.delete,
      null,
      null,
      { id: categoryId }
    );
  }

  forCreateProduct(): Observable<ResultDto<CategoryResultDto[]>> {
    return this.apiService.get<ResultDto<CategoryResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.categories.forCreateProduct
    );
  }

  tree(): Observable<ResultDto<TreeCategoryResultDto[]>> {
    return this.apiService.get<ResultDto<TreeCategoryResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.categories.tree
    );
  }
}

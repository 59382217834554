export class TrendyolUpdateAutoPricingRequestDto {
  constructor(
    id: string,
    minPrice: number,
    changeAmount: number,
    actived: boolean,
    minSellerScore?: number,
  ) {
    this.id = id;
    this.minPrice = minPrice;
    this.changeAmount = changeAmount;
    this.minSellerScore = minSellerScore;
    this.actived = actived;
  }

  id: string;
  minPrice: number;
  changeAmount: number;
  minSellerScore?: number;
  actived: boolean;
}

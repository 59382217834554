import { Component, type OnInit } from '@angular/core';

@Component({
  selector: 'app-general-user-agreement',
  templateUrl: './general-user-agreement.component.html',
  styleUrl: './general-user-agreement.component.scss',
})
export class GeneralUserAgreementComponent implements OnInit {

  ngOnInit(): void { }

}

export class UpdateProductRequestDto {
  constructor(
    productId: string,
    doStore:boolean,
    stockCount: number,
    stockType: number,
    stockChangeType: number,
    stockChangeSubType: number,
    description:string,
    productVarianterId?:string,
  ) {
    this.productId = productId;
    this.doStore=doStore;
    this.stockCount = stockCount;
    this.stockType = stockType;
    this.stockChangeType = stockChangeType;
    this.stockChangeSubType = stockChangeSubType;
    this.description = description;
    this.productVarianterId=productVarianterId;
  }
  productId: string;
  doStore:boolean;
  stockCount: number;
  stockType: number;
  stockChangeType: number;
  stockChangeSubType: number;
  description:string;
  productVarianterId?:string;
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { CurrencyCodeResultDto } from '../../dtos/value-table';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CurrencyTypeService {
  constructor(private apiService: ApiService) {}

  foreignCurrencies(): Observable<CurrencyCodeResultDto[]> {
    return this.apiService.get<CurrencyCodeResultDto[]>(
      null,
      API_ROUTER_UTILS.url.valueTable.foreignCurrencies
    );
  }
}

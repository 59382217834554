import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { API_ROUTER_UTILS } from '../../utils';
import { UnitTypeResultDto } from '../../dtos/value-table';

@Injectable({
  providedIn: 'root',
})
export class UnitTypeService {
  constructor(private apiService: ApiService) {}

  unitTypes(): Observable<UnitTypeResultDto[]> {
    return this.apiService.get<UnitTypeResultDto[]>(
      null,
      API_ROUTER_UTILS.url.valueTable.unitTypes
    );
  }
}

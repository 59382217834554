import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import {
  HepsiBuradaFastCreateProductPreviewResultDto,
  HepsiBuradaFastCreateProductRequestDto,
  InfoForHepsiBuradaNewAdvertResultDto,
} from '../../dtos/hepsiburada';
import { ProductBatchCurrentStatusRequestDto } from '../../dtos/product-batch';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class HepsiBuradaProductBatchService {
  constructor(private apiService: ApiService) {}

  hepsiBuradaBatchRequestCurrentStatus(
    request: ProductBatchCurrentStatusRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.hepsiBuradaProductBatch.currentStatus,
      request,
      null
    );
  }
}

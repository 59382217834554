import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from "../../apis";
import { ResultDto } from "../../dtos/api";
import { API_ROUTER_UTILS } from "../../utils";
import { CreateTrendyolProductRequestDto, TrendyolFilterProductsDto, TrendyolProductRequestDto } from "../../dtos/trendyol";
import { CreateTrendyolAcoountRequestDto } from "../../dtos/trendyol-account";
import { StoreImportRequestDto } from "../../dtos/store-import";
import { TrendyolProductInfoResultDto, TrendyolUpdateProductRequestDto } from "../../dtos/customer-trendyol";

@Injectable({
  providedIn: "root",
})
export class CustomerTrendyolService {
  constructor(private apiService: ApiService) {}

  createTrendyolStore(data: CreateTrendyolAcoountRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.integration.createTrendyolStore,
      data,
      null,
      {}
    );
  }

  storeImportForProducts(
    request: TrendyolProductRequestDto
  ): Observable<ResultDto<TrendyolFilterProductsDto>> {
    return this.apiService.post<ResultDto<TrendyolFilterProductsDto>>(
      API_ROUTER_UTILS.url.batchoperations.storeProductsViewForTrendyol,
      request,
      null
    );
  }

  storeImport(request: StoreImportRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.batchoperations.storeImportForTrendyol,
      request,
      null
    );
  }

  createProduct(
    createProductRequest: CreateTrendyolProductRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.customerTrendyol.createProduct,
      createProductRequest,
      null
    );
  }

  byProductId(
    trendyolProductId: string
  ): Observable<ResultDto<TrendyolProductInfoResultDto>> {
    return this.apiService.get<ResultDto<TrendyolProductInfoResultDto>>(
      null,
      API_ROUTER_UTILS.url.customerTrendyol.byProductId,
      {trendyolProductId: trendyolProductId}
    );
  }

  updateProduct(
    updateProductRequest: TrendyolUpdateProductRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.customerTrendyol.updateProduct,
      updateProductRequest,
      null
    );
  }

}

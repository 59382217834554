<div class="form-group m-form__group" [formGroup]="vatForm">
  <div class="input-group" [ngClass]="{
    'input-error': vatForm!.controls['vatId'].invalid && submitted
  }">
    <div class="input-group-prepend">
      <span class="input-group-text"
      [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >KDV <span *ngIf="required != undefined && required" class="required-input">*</span></span
      >
    </div>
    <ng-select
      appendTo="body"
      class="form-control"
      placeholder="Seçiniz..."
      required=""
      formControlName="vatId"
    >
      <ng-option *ngFor="let item of vats" [value]="item.id">{{
        item.name
      }}</ng-option>
    </ng-select>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto, SearchDto } from '../../dtos/api';
import { API_ROUTER_UTILS } from '../../utils';
import {
  ExportCargoBarcodeRequestDto,
  PackagesPreviewRequestDto,
  PackagesPreviewResultDto,
  PackagesResultDto,
  PackagesSplitPreviewRequestDto,
  PackagesSplitPreviewResultDto,
  PackagesSplitRequestDto,
  PackagesSplitResultDto,
} from '../../dtos/sale';
import { PackagesRequestDto } from '../../dtos/sale/packages-request.dto';

@Injectable({
  providedIn: 'root',
})
export class SaleService {
  constructor(private apiService: ApiService) {}

  sales(searchDto: SearchDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.sales.search,
      searchDto,
      null
    );
  }

  exportCargoBarcode(
    request: ExportCargoBarcodeRequestDto[]
  ): Observable<ResultDto<string>> {
    return this.apiService.post<ResultDto<string>>(
      API_ROUTER_UTILS.url.sales.exportCargoBarcode,
      request,
      null
    );
  }

  packagesPreview(
    request: PackagesPreviewRequestDto[]
  ): Observable<ResultDto<PackagesPreviewResultDto[]>> {
    return this.apiService.post<ResultDto<PackagesPreviewResultDto[]>>(
      API_ROUTER_UTILS.url.sales.packagesPreview,
      request,
      null
    );
  }

  packages(
    request: PackagesRequestDto[]
  ): Observable<ResultDto<PackagesResultDto[]>> {
    return this.apiService.post<ResultDto<PackagesResultDto[]>>(
      API_ROUTER_UTILS.url.sales.packages,
      request,
      null
    );
  }

  packagesSplit(
    request: PackagesSplitRequestDto[]
  ): Observable<ResultDto<PackagesSplitResultDto[]>> {
    return this.apiService.post<ResultDto<PackagesSplitResultDto[]>>(
      API_ROUTER_UTILS.url.sales.packagesSplit,
      request,
      null
    );
  }

  packagesSplitPreview(
    request: PackagesSplitPreviewRequestDto[]
  ): Observable<ResultDto<PackagesSplitPreviewResultDto[]>> {
    return this.apiService.post<ResultDto<PackagesSplitPreviewResultDto[]>>(
      API_ROUTER_UTILS.url.sales.packagesSplitPreview,
      request,
      null
    );
  }
}

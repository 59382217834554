import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import { API_ROUTER_UTILS } from '../../utils';
import { NewOrderSearchAndSaveResultDto } from '../../dtos/sale';
import { TrendyolChangeCargoCompanyRequestDto, TrendyolOrderDetailResultDto, TrendyolUpdatePackagesPickingRequestDto, TrendyolUpdatePackagesPickingResultDto } from '../../dtos/trendyol';

@Injectable({
  providedIn: 'root',
})
export class TrendyolOrderService {
  constructor(private apiService: ApiService) {}

  newSales(): Observable<ResultDto<NewOrderSearchAndSaveResultDto[]>> {
    return this.apiService.get<ResultDto<NewOrderSearchAndSaveResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyolOrders.newOrder
    );
  }

  detail(
    trendyolOrderId: string
  ): Observable<ResultDto<TrendyolOrderDetailResultDto>> {
    return this.apiService.get<ResultDto<TrendyolOrderDetailResultDto>>(
      null,
      API_ROUTER_UTILS.url.trendyolOrders.detail,
      { trendyolOrderId: trendyolOrderId }
    );
  }

  updatePackagesPicking(
    request: TrendyolUpdatePackagesPickingRequestDto[]
  ): Observable<ResultDto<TrendyolUpdatePackagesPickingResultDto[]>> {
    return this.apiService.post<ResultDto<TrendyolUpdatePackagesPickingResultDto[]>>(
      API_ROUTER_UTILS.url.trendyolOrders.updatePackagesPicking,
      request,
      null
    );
  }

  packedChangeCargoCompany(
    request: TrendyolChangeCargoCompanyRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<
      ResultDto
    >(
      API_ROUTER_UTILS.url.trendyolOrders.packedChangeCargoCompany,
      request,
      null
    );
  }
}

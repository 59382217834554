import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { ResultDto } from 'src/app/core/dtos/api';
import { CategoryResultDto } from 'src/app/core/dtos/category';
import { CategoryService } from 'src/app/core/services/customer';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-categories',
  templateUrl: './form-select-categories.component.html',
  styleUrls: ['./form-select-categories.component.scss']
})
export class FormSelectCategoriesComponent implements OnInit {
  @Input() categoryForm: UntypedFormGroup;
  @Input() required?: boolean;
  customerCategories: CategoryResultDto[] = [];
  constructor(
    private categoryService: CategoryService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getCustomerCategories();
  }

  getCustomerCategories() {
    this.categoryService.forCreateProduct().subscribe(
      (data: ResultDto<CategoryResultDto[]>) => {
        this.customerCategories = data.data;
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }
}

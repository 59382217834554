import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { ResultDto, SearchDto } from "../../dtos/api";
import { API_ROUTER_UTILS } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class ReturnService {
  constructor(private apiService: ApiService) {}

  returns(searchDto: SearchDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.returns.search,
      searchDto,
      null
    );
  }
}

<table class="table table-bordered" *ngIf="trendyolOtherSellersDto!=undefined && trendyolOtherSellersDto.length>0">
<thead>
  <th>Satıcı Adı</th>
  <th>Mağaza Puanı</th>
  <th>Satış Fiyatı</th>
  <th></th>
</thead>
<tbody *ngFor="let item of trendyolOtherSellersDto">
   <tr>
    <td>{{item.name}}</td>
    <td>{{item.sellerScore}}</td>
    <td>{{item.sellingPriceText}}</td>
    <td><a target="_blank" href="https:/trendyol.com{{item.url}}" class="fa fa-link"></a></td>
   </tr>
</tbody>
</table>

<div *ngIf="trendyolOtherSellersDto!=undefined && trendyolOtherSellersDto.length==0">
  <ngb-alert [type]="'warning'" [dismissible]="false">
    Bu ürüne ait diğer satıcı bilgisi bulunmamaktadır.
  </ngb-alert>
</div>

import {
  Component,
  Input,
  Output,
  type OnInit,
  EventEmitter,
} from '@angular/core';
import {
  PackagesLineResultDto,
  SalePackagesComponentPacketItemResultDto,
  SalePackagesComponentPacketResultDto,
  SalePackagesComponentResultDto,
} from 'src/app/core/dtos/sale';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from 'src/app/core/services/message';
@Component({
  selector: 'app-sale-packages',
  templateUrl: './sale-packages.component.html',
  styleUrls: ['./sale-packages.component.scss'],
})
export class SalePackagesComponent implements OnInit {
  @Input() lines: PackagesLineResultDto[] = [];
  @Input() uniqueId: string;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updatePackageOutput: EventEmitter<SalePackagesComponentResultDto> =
    new EventEmitter<SalePackagesComponentResultDto>();
  addPackageForm: UntypedFormGroup;
  packageIndex: number = 0;
  addPackageShow: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService
  ) {}
  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.addPackageForm = this.formBuilder.group({
      packets: this.formBuilder.array([]),
    });
  }

  get packetsGroup() {
    return this.addPackageForm.controls['packets'] as UntypedFormArray;
  }

  get isPacketsGroupShow() {
    var allPackets = this.addPackageForm.controls[
      'packets'
    ] as UntypedFormArray;

    var filteredPackets = allPackets.controls.filter(
      (x) => x.get('isPacketShow').value
    );

    return this.formBuilder.array(filteredPackets);
  }

  isPacketItemsGroupChecked(index: number) {
    var allPacketItems = this.packetsGroup.controls[index].get(
      'packetItems'
    ) as UntypedFormArray;

    var filteredPacketItems = allPacketItems.controls.filter(
      (x) => x.get('isLineChecked').value
    );

    return this.formBuilder.array(filteredPacketItems).controls;
  }

  newPackage() {
    var anyQuantity: boolean = false;
    for (let index = 0; index < this.lines.length; index++) {
      var remainigQuantity = this.getRemainingQuantity(
        this.lines[index].stringOrderLineId,
        this.lines[index].longOrderLineId
      );
      if (remainigQuantity > 0) {
        anyQuantity = true;
      }
    }

    if (!anyQuantity) {
      this.messageService.error('Kullanılabilecek kalem kalmadı');
      return;
    }

    var packetInfoText = this.packetsGroup.length + 1 + '. Paket';

    var newPackets = this.formBuilder.group({
      packetInfo: [packetInfoText],
      packetItems: this.formBuilder.array([]),
      isPacketShow: [false, Validators.required],
    });

    for (let index = 0; index < this.lines.length; index++) {
      var remainigQuantity = this.getRemainingQuantity(
        this.lines[index].stringOrderLineId,
        this.lines[index].longOrderLineId
      );

      this.lines[index].maxQuantity = remainigQuantity;

      var newPackageForm = this.formBuilder.group({
        stringOrderLineId: [this.lines[index].stringOrderLineId],
        longOrderLineId: [this.lines[index].longOrderLineId],
        productName: [this.lines[index].productName, Validators.required],
        productImageBase64Url: [this.lines[index].productImageBase64Url],
        quantity: [
          remainigQuantity,
          [
            Validators.required,
            Validators.min(1),
            Validators.max(remainigQuantity),
          ],
        ],
        isLineChecked: [remainigQuantity > 0, Validators.required],
        cargoCompany: [this.lines[index].cargoCompany],
      });

      if (remainigQuantity == 0) {
        newPackageForm.get('isLineChecked').disable();
        newPackageForm.get('quantity').disable();
      }

      var packetItems = newPackets.controls['packetItems'] as UntypedFormArray;
      packetItems.push(newPackageForm);
    }

    this.packageIndex = this.packetsGroup.length;
    this.packetsGroup.push(newPackets);
    this.addPackageShow = true;
  }

  addPackage() {
    var lastPacketGroups = this.packetsGroup.controls[
      this.packetsGroup.controls.length - 1
    ].get('packetItems') as UntypedFormArray;

    var anyIsLineChecked = lastPacketGroups.controls.filter(
      (x) => x.get('isLineChecked').value
    );

    if (anyIsLineChecked.length == 0) {
      this.messageService.error(
        'Tüm satırları pasif yaparak paket ekleyemezisiniz.'
      );
      return;
    }

    var illeagalAnyQuantity: boolean = false;
    for (let index = 0; index < lastPacketGroups.controls.length; index++) {
      if (
        lastPacketGroups.controls[index].get('quantity').value <= 0 &&
        lastPacketGroups.controls[index].get('isLineChecked').value
      ) {
        illeagalAnyQuantity = true;
        break;
      }
    }

    if (illeagalAnyQuantity) {
      this.messageService.error('Adet sayısı sıdırfan büyük olmalıdır.');
    } else {
      this.packetsGroup.controls[this.packageIndex]
        .get('isPacketShow')
        .setValue(true);
      this.packetsGroup.controls[this.packageIndex]
        .get('isPacketShow')
        .updateValueAndValidity();

      this.addPackageShow = false;
    }
  }

  getRemainingQuantity(
    stringOrderLineId?: string,
    longOrderLineId?: number
  ): number {
    var useQuantity = 0;

    var totalCount: number;

    if (stringOrderLineId != null) {
      totalCount = this.lines.find(
        (x) => x.stringOrderLineId == stringOrderLineId
      ).quantity;
      for (let index = 0; index < this.packetsGroup.controls.length; index++) {
        var packetItems = this.packetsGroup.controls[index].get(
          'packetItems'
        ) as UntypedFormArray;

        for (let index2 = 0; index2 < packetItems.controls.length; index2++) {
          if (
            packetItems.controls[index2].get('stringOrderLineId').value ==
              stringOrderLineId &&
            packetItems.controls[index2].get('isLineChecked').value
          ) {
            useQuantity += parseInt(
              packetItems.controls[index2].get('quantity').value
            );
          }
        }
      }
    } else {
      totalCount = this.lines.find(
        (x) => x.longOrderLineId == longOrderLineId
      ).quantity;
      for (let index = 0; index < this.packetsGroup.controls.length; index++) {
        var packetItems = this.packetsGroup.get(
          'packetItems'
        ) as UntypedFormArray;

        for (let index2 = 0; index2 < packetItems.controls.length; index2++) {
          if (
            packetItems.controls[index2].get('longOrderLineId').value ==
              longOrderLineId &&
            packetItems.controls[index2].get('isLineChecked').value
          ) {
            useQuantity += parseInt(
              packetItems.controls[index2].get('quantity').value
            );
          }
        }
      }
    }

    return totalCount - useQuantity;
  }

  closeDialog(): void {
    this.onClose.emit(true);
  }

  outputPackage() {
    var anyQuantity: boolean = false;
    for (let index = 0; index < this.lines.length; index++) {
      var remainigQuantity = this.getRemainingQuantity(
        this.lines[index].stringOrderLineId,
        this.lines[index].longOrderLineId
      );
      if (remainigQuantity > 0) {
        anyQuantity = true;
      }
    }

    if (!anyQuantity) {
      var packets: SalePackagesComponentPacketResultDto[] = [];

      for (let index = 0; index < this.packetsGroup.controls.length; index++) {
        var packetItems = this.packetsGroup.controls[index].get(
          'packetItems'
        ) as UntypedFormArray;
        var packetItemsModel: SalePackagesComponentPacketItemResultDto[] = [];

        for (let index2 = 0; index2 < packetItems.controls.length; index2++) {
          if (packetItems.controls[index2].get('isLineChecked').value) {
            var packetItem = new SalePackagesComponentPacketItemResultDto(
              packetItems.controls[index2].get('productName').value,
              parseInt(packetItems.controls[index2].get('quantity').value.toString()),
              packetItems.controls[index2].get('cargoCompany').value,
              packetItems.controls[index2].get('productImageBase64Url').value,
              packetItems.controls[index2].get('stringOrderLineId').value,
              packetItems.controls[index2].get('longOrderLineId').value
            );

            packetItemsModel.push(packetItem);
          }
        }

        var packet = new SalePackagesComponentPacketResultDto(packetItemsModel);

        packets.push(packet);
      }
      var returnOutput = new SalePackagesComponentResultDto(
        this.uniqueId,
        packets
      );

      this.updatePackageOutput.emit(returnOutput);
    } else {
      this.messageService.error('Bütün kalemleri paketlemediniz.');
    }
  }

  deleteAllPackage() {
    this.packetsGroup.clear();
    this.addPackageShow = false;
  }
}

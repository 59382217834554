import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from '../../services/layout.service';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from 'src/app/core/utils';

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss'],
})
export class CustomizerComponent implements OnInit {
  public screenwidth: any = window.innerWidth;
  public customizer: string = '';
  public layoutType: string = 'ltr';
  public sidebarType: string = 'compact-wrapper';
  public sidebarSetting: string = 'default-sidebar';
  public MIXLayout: string = 'default';

  public primary_color: string = '#7366ff';
  public secondary_color: string = '#f73164';

  constructor(private modalService: NgbModal, public layout: LayoutService, private router: Router) {}

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.screenwidth = event.target.innerWidth;
  }

  ngOnInit() {}

  // Open Modal
  openModal(popup:any) {
    this.modalService.open(popup, {
      backdropClass: 'dark-modal',
      centered: true,
    });
  }

  // Open customizer
  Customizer(val:any) {
    this.customizer = val;
  }

  // Customize Layout Type
  customizeLayoutType(val:any) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if (val == 'rtl') {
      document.getElementsByTagName('html')[0].setAttribute('dir', val);
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    }
  }

  // Customize Sidebar Type
  customizeSidebarType(val:any) {
    this.sidebarType = val;
  }

  // Customize Sidebar Setting
  customizeSidebarSetting(val:any) {
    this.sidebarSetting = val;
    this.layout.config.settings.sidebar_type = val;
  }

  // Customize Mix Layout
  customizeMixLayout(val:any) {
    this.MIXLayout = val;
    this.layout.config.settings.layout_version = val;
  }

  applyColor() {
    document.documentElement.style.setProperty(
      '--theme-deafult',
      this.primary_color
    );
    document.documentElement.style.setProperty(
      '--theme-secondary',
      this.secondary_color
    );
    this.layout.config.color.primary_color = this.primary_color;
    this.layout.config.color.secondary_color = this.secondary_color;
  }

  resetColor() {
    document.documentElement.style.setProperty('--theme-deafult', '#7366ff');
    document.documentElement.style.setProperty('--theme-secondary', '#f73164');
    (<HTMLInputElement>document.getElementById('ColorPicker1')).value =
      '#7366ff';
    (<HTMLInputElement>document.getElementById('ColorPicker2')).value =
      '#f73164';
    this.layout.config.color.primary_color = '#7366ff';
    this.layout.config.color.secondary_color = '#f73164';
  }

  openRetailSale(){
    this.router.navigateByUrl(
      `/${ROUTER_UTILS.config.retailSale.root}`
    );
  }
}

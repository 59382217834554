import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { ResultDto } from "../../dtos/api";
import { UpdatePricePreviewResultDto, UpdatePriceRequestDto, UpdateVatPreviewResultDto, UpdateVatRequestDto } from "../../dtos/batch";
import { API_ROUTER_UTILS } from "../../utils";
@Injectable({
  providedIn: "root",
})
export class BatchService {
  constructor(private apiService: ApiService) {}

  updatePrice(
    request: UpdatePriceRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.batchoperations.updatePrice,
      request,
      null,
      {}
    );
  }

  updatePricePreview(
    request: UpdatePriceRequestDto
  ): Observable<ResultDto<UpdatePricePreviewResultDto[]>> {
    return this.apiService.post<ResultDto<UpdatePricePreviewResultDto[]>>(
      API_ROUTER_UTILS.url.batchoperations.updatePricePreview,
      request,
      null,
      {}
    );
  }

  updateVat(
    request: UpdateVatRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.batchoperations.updateVat,
      request,
      null,
      {}
    );
  }

  updateVatPreview(
    request: UpdateVatRequestDto
  ): Observable<ResultDto<UpdateVatPreviewResultDto[]>> {
    return this.apiService.post<ResultDto<UpdateVatPreviewResultDto[]>>(
      API_ROUTER_UTILS.url.batchoperations.updateVatPreview,
      request,
      null,
      {}
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BrandResultDto } from 'src/app/core/dtos/brand';
import { BrandService } from 'src/app/core/services/customer/brand.service';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-brands',
  templateUrl: './form-select-brands.component.html',
  styleUrls: ['./form-select-brands.component.scss'],
})
export class FormSelectBrandsComponent implements OnInit {
  @Input() brandForm!: UntypedFormGroup;
  @Input() required?: boolean;
  customerBrands: BrandResultDto[] = [];
  constructor(
    private brandService: BrandService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getCustomerBrands();
  }

  getCustomerBrands() {
    this.brandService.all().subscribe(
      (data: BrandResultDto[]) => {
        this.customerBrands = data;
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }
}

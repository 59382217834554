import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto, SearchDto } from '../../dtos/api';
import {
  ChangePasswordRequestDto,
  CreateRolePermissionRequestDto,
  CreateUserRequestDto,
  RolePaginationResponseDto,
  RolePermissionPaginationResponseDto,
  UserDto,
  UserPaginationResponseDto,
  UserPermissionPaginationResponseDto,
} from '../../dtos/user';
import { RoleClaimListFilterDto } from '../../dtos/user/role-claim-list-filter.dto';
import { UserPermissionListFilterDto } from '../../dtos/user/user-permission-list-filter.dto';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiService) {}

  getUsers(search: SearchDto): Observable<UserPaginationResponseDto> {
    return this.apiService.post<UserPaginationResponseDto>(
      API_ROUTER_UTILS.url.users.search,
      search,
      null
    );
  }

  userInfo(): Observable<ResultDto<UserDto>> {
    return this.apiService.get<ResultDto<UserDto>>(
      null,
      API_ROUTER_UTILS.url.users.info
    );
  }

  superAdminCreateUser(
    createUser: CreateUserRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.users.superAdminCreateUser,
      createUser,
      null
    );
  }

  getRoles(search: SearchDto): Observable<RolePaginationResponseDto> {
    return this.apiService.post<RolePaginationResponseDto>(
      API_ROUTER_UTILS.url.roles.search,
      search,
      null
    );
  }

  getRolePermissions(
    searchClaimListFilter: RoleClaimListFilterDto
  ): Observable<RolePermissionPaginationResponseDto> {
    return this.apiService.post<RolePermissionPaginationResponseDto>(
      API_ROUTER_UTILS.url.roles.permisisons,
      searchClaimListFilter,
      null
    );
  }

  createRolePermission(
    createRolePermissionRequestDto: CreateRolePermissionRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.roles.createRolePermission,
      createRolePermissionRequestDto,
      null
    );
  }

  getUserPermissions(
    userPermissionListFilterDto: UserPermissionListFilterDto
  ): Observable<UserPermissionPaginationResponseDto> {
    return this.apiService.post<UserPermissionPaginationResponseDto>(
      API_ROUTER_UTILS.url.users.userPermissions,
      userPermissionListFilterDto,
      null
    );
  }

  changePassword(request: ChangePasswordRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.users.changePassword,
      request,
      null
    );
  }
}

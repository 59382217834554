import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../apis";
import { ResultDto, SearchDto } from "../../dtos/api";
import { ProductBatchCurrentStatusRequestDto, ProductBatchPaginationReponseDto } from "../../dtos/product-batch";
import { API_ROUTER_UTILS } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class ProductBatchOperationService {
  constructor(private apiService: ApiService) {}

  postProductBatchs(
    search: SearchDto
  ): Observable<ResultDto<ProductBatchPaginationReponseDto>> {
    return this.apiService.post<ResultDto<ProductBatchPaginationReponseDto>>(
      API_ROUTER_UTILS.url.batchoperations.postProductBatchsSearch,
      search,
      null,
      {}
    );
  }

  currentStatus(
    request: ProductBatchCurrentStatusRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.batchoperations.currentStatus,
      request,
      null,
      {}
    );
  }
}

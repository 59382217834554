<div class="row">
  <div class="col-md-12">
    <div class="list-group">
      <a
        *ngFor="let trendyolAutoPricingLog of trendyolAutoPricingLogDto"
        class="list-group-item list-group-item-action flex-column align-items-start"
      >
        <div class="row">
          <div class="col-md-6">
            <div *ngIf="trendyolAutoPricingLog.successed">
              <i class="active-element fa fa-info-circle"></i>
            </div>
            <div *ngIf="!trendyolAutoPricingLog.successed">
              <i
                ngbTooltip="{{ trendyolAutoPricingLog.errorText }}"
                tooltipClass="tooltip-error"
                class="passive-element fa fa-info-circle"
              ></i>
            </div>
            <div *ngIf="trendyolAutoPricingLog.afterPrice != null">
              <span
                ><b>Önceki Fiyat: </b>
                {{ trendyolAutoPricingLog.beforePrice }} ₺
              </span>
            </div>
            <div *ngIf="trendyolAutoPricingLog.afterPrice != null">
              <span
                ><b>Güncellenecek Fiyat: </b>
                {{ trendyolAutoPricingLog.afterPrice }} ₺
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div *ngIf="trendyolAutoPricingLog.executedOnString != null">
              <b
                >Çalıştırılma Zamanı:</b>
                {{ trendyolAutoPricingLog.executedOnString }}
            </div>
            <div>
              <b
                >Eklenme Zamanı:</b>
                {{ trendyolAutoPricingLog.createdOnString }}

            </div>
            <div *ngIf="trendyolAutoPricingLog.successed">
              <button class="button btn btn-outline-primary">Güncelleme Zamanındaki Diğer Satıcılar</button>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

export class ExportCargoBarcodeRequestDto {
  constructor(
    orderId: string,
    storeTypeName: string
  ) {
    this.orderId = orderId;
    this.storeTypeName = storeTypeName;
  }

  orderId: string;
  storeTypeName: string;
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResultDto } from 'src/app/core/dtos/api';
import { UserDto } from 'src/app/core/dtos/user';
import { AuthService } from 'src/app/core/services/auth';
import { MessageService } from 'src/app/core/services/message';
import { UserService } from 'src/app/core/services/user';
import {
  PERMISSION_UTILS,
  ROUTER_UTILS,
  httpErrorResponseUtil,
} from 'src/app/core/utils';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  userInfoModel: UserDto;
  permissionUtils = PERMISSION_UTILS;
  constructor(
    public authService: AuthService,
    private router: Router,
    private userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.userInfo();
  }

  signOut() {
    this.authService.signOut();
    const { root, signIn } = ROUTER_UTILS.config.auth;
    this.router.navigate(['/', root, signIn]);
  }

  userInfo() {
    this.userService.userInfo().subscribe(
      (data: ResultDto<UserDto>) => {
        if (data.success) {
          this.userInfoModel = data.data;
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  openFirmInfo() {
    this.router.navigateByUrl(
      `/${ROUTER_UTILS.config.firm.root}/${ROUTER_UTILS.config.firm.firmInfo}`
    );
  }

  profile() {
    this.router.navigateByUrl(
      `/${ROUTER_UTILS.config.user.root}/${ROUTER_UTILS.config.user.profile}`
    );
  }
}

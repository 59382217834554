import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { VatDto } from 'src/app/core/dtos/value-table';
import { MessageService } from 'src/app/core/services/message';
import { VatService } from 'src/app/core/services/value-table';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-vats',
  templateUrl: './form-select-vats.component.html',
  styleUrls: ['./form-select-vats.component.scss']
})
export class FormSelectVatsComponent implements OnInit {
  @Input() vatForm: UntypedFormGroup;
  @Input() required?: boolean;
  @Input() submitted: boolean;
  vats: VatDto[] = [];
  constructor(
    private vatService: VatService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getVats();
  }

  getVats() {
    this.vatService.vats().subscribe(
      (data: VatDto[]) => {
        this.vats = data;
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }
}

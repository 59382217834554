import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ResultDto } from 'src/app/core/dtos/api';
import { TrendyolCreateProductCategoryResultDto } from 'src/app/core/dtos/trendyol';
import { MessageService } from 'src/app/core/services/message';
import { TrendyolService } from 'src/app/core/services/trendyol';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-trendyol-categories',
  templateUrl: './form-select-trendyol-categories.component.html',
  styleUrls: ['./form-select-trendyol-categories.component.scss']
})
export class FormSelectTrendyolCategoriesComponent implements OnInit {

  @Input() categoryForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Output() changeCategoryEmit = new EventEmitter<string | undefined>();

  trendyolCategories: TrendyolCreateProductCategoryResultDto[] = [];
  constructor(
    private trendyolService: TrendyolService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  forCreateProductCategories(searchKey: string) {
    this.trendyolService.forCreateProductCategories(searchKey).subscribe(
      (data: ResultDto<TrendyolCreateProductCategoryResultDto[]>) => {
        this.trendyolCategories = data.data;
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  onSearch($event: any) {
    if ($event.term.length >= 3) {
      this.forCreateProductCategories($event.term);
    }
  }

  onChange($event: number | undefined) {
    if($event==undefined){
      this.changeCategoryEmit.emit(undefined);
    }
    else{
      let trendyolCategory= this.trendyolCategories.filter((element)=>element.categoryId==$event)[0];

      this.changeCategoryEmit.emit(trendyolCategory.id);
    }

  }

}

<div [formGroup]="varianterForm">
  <div class="row">
    <div class="col-md-3">
      <div class="form-group m-form__group">
        <div class="media">
          <label class="m-r-10">Varyant</label>
          <div class="media-body icon-state switch-outline">
            <label class="switch">
              <input
                type="checkbox"
                formControlName="isVariant"
                (change)="variantSwitchChange($event)"
              /><span class="switch-state bg-success"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="varianterSwitch">
      <div class="form-group m-form__group">
        <ng-select
          formControlName="variants"
          class="js-example-disabled"
          [(ngModel)]="selectedVariants"
          [multiple]="true"
          placeholder="Varyant tiplerini seçiniz."
          (change)="variantSelectChange($event)"
        >
          <ng-option *ngFor="let item of varianters" [value]="item.id">{{
            item.name
          }}</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="col-md-3" *ngIf="varianterSwitch">
      <button
        [disabled]="createVariantButtonDisabled"
        (click)="createVariantRow()"
        class="btn btn-info mr-1"
      >
        Ekle
      </button>
    </div>
  </div>
  <div formArrayName="variantGroup">
    <div
      class="form-box-group"
      *ngFor="
        let variantRowCount of variantGroups().controls;
        let variantGroupIndex = index
      "
    >
      <div [formGroupName]="variantGroupIndex">
        <div class="variants-field">
          <div class="float-left" style="width: 40%; padding: 5px">
            <button class="btn btn-outline-success" (click)="file.click()">
              <i class="fa fa-upload"></i>
              Varyant Resmi Yükle
            </button>
            <input
              #file
              type="file"
              (change)="detectFiles($event, variantGroupIndex)"
              multiple
              style="display: none"
              accept=".jpg,.png,.jpeg"
            />
            <div
              cdkDropList
              #todoList="cdkDropList"
              cdkDropListOrientation="horizontal"
              class="example-list"
              (cdkDropListDropped)="drop($event, variantGroupIndex)"
            >
              <div
                class="example-box"
                cdkDrag
                formArrayName="varianterImages"
                *ngFor="
                  let image of varianterImages(variantGroupIndex).controls;
                  let imageIndex = index
                "
              >
                <div style="position: relative">
                  <div [formGroupName]="imageIndex">
                    <img
                      [src]="image.get('url').value"
                      height="70"
                      width="70"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="text-center"
              style="background-color: #ebeaea; height: 80px"
            >
              <div
                formArrayName="varianterImages"
                *ngFor="
                  let image of varianterImages(variantGroupIndex).controls;
                  let imageIndex = index
                "
              >
                <div [formGroupName]="imageIndex">
                  <img
                    [src]="image.controls.url.value"
                    width="50"
                    height="50"
                    alt="Image Preview"
                  />
                </div>
              </div>
            </div> -->
          </div>
          <div class="float-left" style="width: 60%">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group m-form__group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bold"
                        >Barkod<span class="required-input">*</span></span
                      >
                    </div>
                    <input
                      class="form-control"
                      [type]="'text'"
                      required=""
                      formControlName="barcode"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group m-form__group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bold"
                        >Maliyet(Kdv Dahil)<span class="required-input"
                          >*</span
                        ></span
                      >
                    </div>
                    <input
                      class="form-control"
                      [type]="'text'"
                      required=""
                      formControlName="price"
                      currencyInput
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <span
                  class="fa fa-trash"
                  style="color: red"
                  (click)="removeVariantGroup(variantGroupIndex)"
                ></span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div class="form-group m-form__group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bold"
                        >Stok Kodu<span class="required-input">*</span></span
                      >
                    </div>
                    <input
                      class="form-control"
                      [type]="'text'"
                      required=""
                      formControlName="stockCode"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group m-form__group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bold"
                        >Stok Miktarı<span class="required-input">*</span></span
                      >
                    </div>
                    <input
                      class="form-control"
                      [type]="'number'"
                      required=""
                      formControlName="stockCount"
                      onlyNumbers
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="varianterSubGroup">
          <div class="row">
            <div
              class="col-md-3"
              *ngFor="
                let formVariant of variantSubGroups(variantGroupIndex).controls;
                let variantSubGroupIndex = index
              "
            >
              <div
                class="form-group m-form__group"
                [formGroupName]="variantSubGroupIndex"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bold"
                      >{{ formVariant.get("variantName").value }}
                      <span class="required-input">*</span></span
                    >
                  </div>
                  <ng-select
                    class="form-control js-example-disabled"
                    formControlName="variantValueId"
                    placeholder="Seçiniz..."
                  >
                    <ng-option
                      *ngFor="
                        let item of getVarianterValues(
                          formVariant.get('variantId').value
                        )
                      "
                      [value]="item.id"
                      >{{ item.value }}</ng-option
                    >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, type OnInit } from '@angular/core';

@Component({
  selector: 'app-general-user-agreement-dialog',
  templateUrl: './general-user-agreement-dialog.component.html',
  styleUrl: './general-user-agreement-dialog.component.scss',
})
export class GeneralUserAgreementDialogComponent implements OnInit {
  ngOnInit(): void {}
}

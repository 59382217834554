import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import { AllTrendyolCategoryInfoResultDto, BrandResultDto, CategoryAttributeResultDto, TrendyolCreateProductCategoryResultDto, TrendyolFilterProductsDto, TrendyolProductRequestDto } from '../../dtos/trendyol';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class TrendyolService {
  constructor(private apiService: ApiService) {}

  filterProducts(
    request: TrendyolProductRequestDto
  ): Observable<TrendyolFilterProductsDto> {
    return this.apiService.post<TrendyolFilterProductsDto>(
      API_ROUTER_UTILS.url.batchoperations.storeImportForTrendyol,
      request,
      null
    );
  }

  forCreateProductCategories(
    searchKey: string
  ): Observable<ResultDto<TrendyolCreateProductCategoryResultDto[]>> {
    return this.apiService.get<
      ResultDto<TrendyolCreateProductCategoryResultDto[]>
    >(null, API_ROUTER_UTILS.url.trendyol.forCreateProductCategories, {
      searchKey: searchKey,
    });
  }

  searchBrands(searchKey: string): Observable<ResultDto<BrandResultDto[]>> {
    return this.apiService.get<ResultDto<BrandResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyol.searchBrands,
      { searchKey: searchKey }
    );
  }

  categoryAttributes(
    trendyolCategoryId: string
  ): Observable<ResultDto<CategoryAttributeResultDto[]>> {
    return this.apiService.get<ResultDto<CategoryAttributeResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyol.categoryAttributes,
      { trendyolCategoryId: trendyolCategoryId }
    );
  }

  allTrendyolCategoryInfo(): Observable<ResultDto<AllTrendyolCategoryInfoResultDto[]>> {
    return this.apiService.get<ResultDto<AllTrendyolCategoryInfoResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyol.allTrendyolCategoryInfo,
      null
    );
  }

  createProductCategoryAttributes(categoryId:number): Observable<ResultDto> {
    return this.apiService.get<ResultDto>(
      null,
      API_ROUTER_UTILS.url.trendyol.createProductCategoryAttributes,
      {id:categoryId}
    );
  }
}

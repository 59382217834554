import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import { StoreResultDto } from '../../dtos/store';
import { API_ROUTER_UTILS } from '../../utils';
import { StoreImportInfoResultDto } from '../../dtos/store-import';

@Injectable({
  providedIn: 'root',
})
export class CommonStoreService {
  constructor(private apiService: ApiService) {}

  customerStores(): Observable<ResultDto<StoreResultDto[]>> {
    return this.apiService.get<ResultDto<StoreResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.integration.stores
    );
  }

  customerStoresByCustomerId(
    customerId: string
  ): Observable<ResultDto<StoreResultDto[]>> {
    return this.apiService.get<ResultDto<StoreResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.integration.storesByCustomerId,
      { customerId: customerId }
    );
  }

  storeImportInfo(): Observable<ResultDto<StoreImportInfoResultDto>> {
    return this.apiService.get<ResultDto<StoreImportInfoResultDto>>(
      null,
      API_ROUTER_UTILS.url.integration.storeImportInfo
    );
  }
}

<div class="form-group m-form__group" [formGroup]="foreignCurrencyForm">
  <div class="input-group"  [ngClass]="{
    'input-error': foreignCurrencyForm.get('foreignCurrencyId')!.invalid && submitted
  }">
    <div class="input-group-prepend">
      <span class="input-group-text font-weight-bold"
        >Döviz <span class="required-input">*</span></span
      >
    </div>
    <ng-select
      class="form-control"
      placeholder="Seçiniz..."
      required=""
      formControlName="foreignCurrencyId"
    >
      <ng-option *ngFor="let item of foreignCurrencies" [value]="item.id">{{
        item.code
      }}</ng-option>
    </ng-select>
  </div>
</div>

<div *ngIf="imageBase64Url != null">
  <img [src]="imageBase64Url" style="max-width: 500px; max-height: 500px" />
</div>

<div *ngIf="imageHttpUrl != null">
  <img [src]="imageHttpUrl" style="max-width: 500px; max-height: 500px" />
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Kapat</button>
</mat-dialog-actions>

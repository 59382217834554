export class CreateNewAdvertControlRequestDto {
  constructor(storeId: string, storeTypeId: string, storeTypeName: string ,productId: string) {
    this.storeId = storeId;
    this.storeTypeId = storeTypeId;
    this.storeTypeName=storeTypeName;
    this.productId = productId;
  }

  storeId: string;
  storeTypeId: string;
  storeTypeName: string;
  productId: string;
}

export class PackagesPreviewResultDto {
  constructor(
    orderId:string,
    storeId:string,
    storeName: string,
    storeTypeName: string,
    storeTypeImageBase64Url: string,
    lines: PackagesLineResultDto[],
    packagables: PackagesGroupPackagableLineResultDto[],
    uniqueId: string,
    stringOrderNumber?: string,
    longOrderNumber?: number,
  ) {
    this.storeId=storeId;
    this.orderId=orderId;
    this.storeName = storeName;
    this.storeTypeName = storeTypeName;
    this.storeTypeImageBase64Url = storeTypeImageBase64Url;
    this.uniqueId = uniqueId;
    this.stringOrderNumber = stringOrderNumber;
    this.longOrderNumber = longOrderNumber;
    this.lines = lines;
    this.packagables = packagables;
  }
  storeId:string;
  orderId:string;
  storeName: string;
  storeTypeName: string;
  storeTypeImageBase64Url: string;
  uniqueId: string;
  stringOrderNumber?: string;
  longOrderNumber?: number;
  lines: PackagesLineResultDto[];
  packagables: PackagesGroupPackagableLineResultDto[];
}

export class PackagesLineResultDto {
  constructor(
    productName: string,
    quantity: number,
    cargoCompany: string,
    productImageBase64Url?: string,
    stringOrderLineId?: string,
    longOrderLineId?: number,
    maxQuantity?: number
  ) {
    this.productName = productName;
    this.quantity = quantity;
    this.cargoCompany = cargoCompany;
    this.stringOrderLineId = stringOrderLineId;
    this.longOrderLineId = longOrderLineId;
    this.productImageBase64Url = productImageBase64Url;
    this.maxQuantity = maxQuantity;
  }

  productName: string;
  quantity: number;
  cargoCompany: string;
  stringOrderLineId?: string;
  longOrderLineId?: number;
  productImageBase64Url?: string;
  maxQuantity?: number;
}

export class PackagesGroupPackagableLineResultDto {
  constructor(
    groupId: number,
    packagableGroups: PackagesPackagableLineResultDto[]
  ) {
    this.groupId = groupId;
    this.packagableGroups = packagableGroups;
  }

  groupId: number;
  packagableGroups: PackagesPackagableLineResultDto[];
}

export class PackagesPackagableLineResultDto {
  constructor(
    productName: string,
    quantity: number,
    cargoCompany:string,
    productImageBase64Url?: string,
    stringOrderLineId?: string,
    longOrderLineId?: number
  ) {
    this.productName = productName;
    this.quantity = quantity;
    this.cargoCompany=cargoCompany;
    this.stringOrderLineId = stringOrderLineId;
    this.longOrderLineId = longOrderLineId;
    this.productImageBase64Url = productImageBase64Url;
  }

  productName: string;
  quantity: number;
  cargoCompany:string;
  stringOrderLineId?: string;
  productImageBase64Url?: string;
  longOrderLineId?: number;
}

<div class="form-group m-form__group" [formGroup]="autoPricingForm">
  <div class="media">
    <label class="m-r-10">Pasif / Aktif</label>
    <div class="media-body icon-state switch-outline">
      <label class="switch">
        <input
          [ngModel]="activedSwitch"
          [checked]="activedSwitch"
          type="checkbox"
          formControlName="actived"
        /><span class="switch-state bg-success"></span>
      </label>
    </div>
  </div>
</div>

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getMessaging, getToken } from 'firebase/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavService } from 'src/app/shared/services/nav.service';
import { ApiService } from '../../apis';
import {
  AccessTokenDto,
  SignInDto,
  SignUpDto,
  TrialCreationFirstDto,
  TrialCreationFirstResultDto,
  TrialCreationSecondDto,
  UserRoleDto,
} from '../../dtos/auth';
import { StorageItem } from '../../enums';
import {
  API_ROUTER_UTILS,
  getItem,
  removeItem,
  ROUTER_UTILS,
  setItem,
} from '../../utils';
import { ApiHandleService } from '../api-handle';
import { environment } from 'src/environments/environment';
import { DeviceTypeConstant, Roles } from '../../constants';
import { MANAGEMENT_ROUTER_UTILS } from '../../utils/management-router.util';
import { HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Messaging } from '@angular/fire/messaging';
import { ResultDto } from '../../dtos/api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private apiHandleService: ApiHandleService,
    private navService: NavService,
    private jwtHelperService: JwtHelperService
  ) {}

  private messaging = inject(Messaging);
  isLoggedIn$ = new BehaviorSubject<boolean>(!!getItem(StorageItem.Auth));
  userRole$ = new BehaviorSubject<string>('');

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  get userRoleValue(): string {
    return this.userRole$.getValue();
  }

  signIn(data: SignInDto, returnUrl: string): void {
    getToken(this.messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          data.deviceToken = currentToken;
          data.deviceTypeId = DeviceTypeConstant.web;
          this.apiService
            .post<AccessTokenDto>(
              API_ROUTER_UTILS.url.auth.tokens,
              data,
              null,
              {}
            )
            .subscribe({
              next: (res) => this.handleSignResponse(res, returnUrl),
              error: (err: HttpErrorResponse) =>
                this.apiHandleService.handleError(err),
            });
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  signUp(data: SignUpDto): void {
    this.apiService
      .post<string>(API_ROUTER_UTILS.url.auth.signUp, data, null, {})
      .subscribe({
        next: (res) => this.handleSignUpResponse(res),
        error: (err: HttpErrorResponse) =>
          this.apiHandleService.handleError(err),
      });
  }

  signOut(): void {
    removeItem(StorageItem.Auth);
    removeItem(StorageItem.RefreshToken);
    this.isLoggedIn$.next(false);
    this.userRole$.next('');
  }

  trialCreationFirst(
    request: TrialCreationFirstDto
  ): Observable<ResultDto<TrialCreationFirstResultDto>> {
    return this.apiService.post<ResultDto<TrialCreationFirstResultDto>>(
      API_ROUTER_UTILS.url.auth.trialCreationFirst,
      request,
      null
    );
  }

  trialCreationSecond(
    request: TrialCreationSecondDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.auth.trialCreationSecond,
      request,
      null
    );
  }

  async loadUserRole() {
    const res = this.apiService
      .get<UserRoleDto>(null, API_ROUTER_UTILS.url.auth.userRole)
      .toPromise();

    const res2 = await Promise.all([res]);
    const val = res2[0];
    this.navService.refreshMenu(val!.roleName);
    this.userRole$.next(val!.roleName);
  }

  getData(): Observable<string> {
    return this.userRole$;
  }

  private handleSignResponse(data: AccessTokenDto, returnUrl: string) {
    setItem(StorageItem.Auth, data.token);
    setItem(StorageItem.RefreshToken, data.refreshToken);
    this.isLoggedIn$.next(true);
    this.userRole$.next(data.userRole);
    this.navService.refreshMenu(data.userRole);

    if (data.userRole == Roles.superAdmin) {
      this.router.navigateByUrl(
        `/${MANAGEMENT_ROUTER_UTILS.config.version.root}/${MANAGEMENT_ROUTER_UTILS.config.version.versions}`
      );
    } else {
      if (returnUrl === '/') {
        this.router.navigateByUrl(
          `/${ROUTER_UTILS.config.home.root}/${ROUTER_UTILS.config.home.versions}`
        );
      } else {
        this.router.navigate([returnUrl]);
      }
    }
  }

  private handleSignUpResponse(res: string) {
    this.router.navigateByUrl(
      `/${ROUTER_UTILS.config.auth.root}/${ROUTER_UTILS.config.auth.signIn}`
    );
  }

  getPermissions(): string[] {
    const token = localStorage.getItem(StorageItem.Auth);
    const decodedToken = this.jwtHelperService.decodeToken(token);

    var jsonPermissions = decodedToken['permission'];
    if (jsonPermissions == null || jsonPermissions == undefined) {
      return [];
    }

    return JSON.parse(jsonPermissions) as string[];
  }

  hasPermission(permission: string): boolean {
    const permissions = this.getPermissions();
    return permissions.includes(permission);
  }

  hasRole(role: string): boolean {
    const token = localStorage.getItem(StorageItem.Auth);
    const decodedToken = this.jwtHelperService.decodeToken(token);

    var userRole =
      decodedToken[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ];

    return role == userRole;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  NgbModal,
  NgbModalConfig,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ResultDto } from 'src/app/core/dtos/api';
import { CreateTrendyolAutoPricingRequestDto } from 'src/app/core/dtos/auto-pricing';
import { TrendyolAutoPricingService } from 'src/app/core/services/auto-pricing';
import { MessageService } from 'src/app/core/services/message';
import {
  httpErrorResponseUtil,
  ngbDateFormatToString,
  ROUTER_UTILS,
  stringToDouble,
  stringToInt,
} from 'src/app/core/utils';
import { markFormGroupTouched } from 'src/app/core/utils/forms/mark_form_group_touched.util';

@Component({
  selector: 'app-trendyol-auto-pricing',
  templateUrl: './trendyol-auto-pricing.component.html',
  styleUrls: ['./trendyol-auto-pricing.component.scss'],
})
export class TrendyolAutoPricingComponent implements OnInit {
  @Input() trendyolProductId: string;
  routerUtils = ROUTER_UTILS;
  autoPricingForm: UntypedFormGroup;
  otherSellerModalReference: NgbModalRef;
  constructor(
    private trendyolAutoPricingService: TrendyolAutoPricingService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    //modal settings
    config.backdrop = 'static';
    config.keyboard = false;

    this.autoPricingFormLoad();
  }

  ngOnInit(): void {}

  autoPricingFormLoad() {
    this.autoPricingForm = this.formBuilder.group({
      minPrice: [null, [Validators.required]],
      changeAmount: [null, [Validators.required]],
      actived: [null, [Validators.required]],
      minSellerScore: [null, [Validators.min(1), Validators.max(10)]],
    });
  }

  createAutoPricing(postData: any) {
    markFormGroupTouched(this.autoPricingForm);
    if (this.autoPricingForm.invalid) {
      return;
    }

    let createTrendyolAutoPricingRequestDto =
      new CreateTrendyolAutoPricingRequestDto(
        this.trendyolProductId,
        stringToDouble(postData.minPrice),
        stringToDouble(postData.changeAmount),
        postData.actived,
        stringToInt(postData.minSellerScore)
      );

    this.trendyolAutoPricingService
      .createTrendyolAutoPricing(createTrendyolAutoPricingRequestDto)
      .subscribe(
        (data: ResultDto) => {
          if (data.success) {
            this.messageService.success(data.message);
            this.closeTrendyolAutoPricingModal();
          } else {
            for (let index = 0; index < data.errors.length; index++) {
              this.messageService.error(data.errors[index]);
            }
          }
        },
        (error: HttpErrorResponse) => {
          var errorStr = httpErrorResponseUtil(error);
          this.messageService.error(errorStr);
        }
      );
  }

  closeTrendyolAutoPricingModal() {
    this.modalService.dismissAll();
    //this.selectedAutoPricingTrendyolProductId = undefined;
  }

  otherSellers(otherSellerModal: any) {
    this.openTrendyolOtherSellersModal(otherSellerModal);
  }

  openTrendyolOtherSellersModal(otherSellerModal: any) {
    this.otherSellerModalReference = this.modalService.open(otherSellerModal, {
      size: 'lg',
    });
  }

  closeTrendyolOtherSellersModal() {
    this.otherSellerModalReference.close();
  }
}

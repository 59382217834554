import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto, SearchDto } from '../../dtos/api';
import { API_ROUTER_UTILS } from '../../utils';
import { NewReturnSearchAndSaveResultDto } from '../../dtos/return';

@Injectable({
  providedIn: 'root',
})
export class HepsiBuradaReturnService {
  constructor(private apiService: ApiService) {}

  newReturns(): Observable<ResultDto<NewReturnSearchAndSaveResultDto[]>> {
    return this.apiService.get<ResultDto<NewReturnSearchAndSaveResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.hepsiBuradaReturns.newReturn
    );
  }
}

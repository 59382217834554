import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[onlyNumbers]',
})
export class OnlyNumbersDirective {
  @Input()
  public min?: number;

  @Input()
  public max?: number;

  @HostBinding('autocomplete') public autocomplete;

  constructor(private ref: ElementRef) {
    this.autocomplete = 'off';
  }

  @HostListener('input', ['$event'])
  public onInput(): void {
    let val = parseInt(this.ref.nativeElement.value);
    if (this.max !== null && this.max !== undefined && val >= this.max)
      this.ref.nativeElement.value = this.max.toString();
    else if (this.min !== null && this.min !== undefined && val <= this.min)
      this.ref.nativeElement.value = this.min.toString();
  }

  @HostListener('keypress', ['$event']) public disableKeys(e: any) {
    document.all ? e.keyCode : e.keyCode;
    return e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57);
  }
}

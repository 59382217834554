import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { VatDto } from '../../dtos/value-table';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class VatService {
  constructor(private apiService: ApiService) {}

  vats(): Observable<VatDto[]> {
    return this.apiService.get<VatDto[]>(
      null,
      API_ROUTER_UTILS.url.valueTable.vats
    );
  }
}

<div class="modal-header">
  <h4
    class="modal-title"
    id="modal-basic-title"
    *ngIf="updateProduct != null && updateProduct != undefined"
  >
    "{{ updateProduct.title }}" Ürünü Stok Güncelle
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div
      class="row"
      *ngIf="updateProduct != null && updateProduct != undefined"
    >
      <div class="col-md-12">
        <form class="theme-form" [formGroup]="updateForm">
          <div class="row">
            <div class="=col-md-12">
              <div><b>Güncel Stok: </b>{{ updateProduct.stock }}</div>
            </div>
          </div>
          <div class="row">
            <div class="=col-md-12">
              <mat-checkbox
                [(ngModel)]="doStoreDefault"
                formControlName="doStore"
                >Yapacağınız değişim pazaryerlerinizede uygulansın
                mı?</mat-checkbox
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Stok(Ekle/Çıkart Sayısı)</mat-label>
                <input
                  matInput
                  formControlName="stockCount"
                  onlyNumbers
                 [min]="1"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Ürün Varyantı Seç</mat-label>
                <mat-select formControlName="productVarianterId">
                  <mat-option
                    *ngFor="let item of updateProduct.productVarianters"
                    [value]="item.id"
                  >
                    {{ item.barcode }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Değişim</mat-label>
                <mat-select formControlName="stockType">
                  <mat-option value="0">Stok Ekleme</mat-option>
                  <mat-option value="1">Stok Çıkartma</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Stok Değişim Tipi</mat-label>
                <mat-select formControlName="stockChangeType">
                  <mat-option value="0">Mağaza</mat-option>
                  <mat-option value="1">Perakende Satış</mat-option>
                  <mat-option value="2">Müşteri</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Stok Değişim Alt Tipi</mat-label>
                <mat-select formControlName="stockChangeSubType">
                  <mat-option value="0">Satış</mat-option>
                  <mat-option value="1">İade</mat-option>
                  <mat-option value="2">Ürün Ekleme</mat-option>
                  <mat-option value="3">Ürün Güncelleme</mat-option>
                  <mat-option value="4">İçe Aktarma</mat-option>
                  <mat-option value="5">Fire</mat-option>
                  <mat-option value="6">Son Kullanma Tarihi Geçmiş</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Açıklama</mat-label>
                <textarea matInput formControlName="description"></textarea>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="submit"
    class="btn btn-primary"
    (click)="update(updateForm.value)"
  >
    Güncelle
  </button>
  <button class="btn btn-light" (click)="closeModal()">Kapat</button>
</div>

<app-loading [loading]="loading"></app-loading>

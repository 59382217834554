import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import {
  HepsiBuradaCategoryAttributeResultDto,
  HepsiBuradaCreateProductCategoryResultDto,
} from '../../dtos/hepsiburada';
import {
  BrandResultDto,
  CategoryAttributeResultDto,
  TrendyolCreateProductCategoryResultDto,
  TrendyolFilterProductsDto,
  TrendyolProductRequestDto,
} from '../../dtos/trendyol';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class HepsiBuradaService {
  constructor(private apiService: ApiService) {}

  filterProducts(
    request: TrendyolProductRequestDto
  ): Observable<TrendyolFilterProductsDto> {
    return this.apiService.post<TrendyolFilterProductsDto>(
      API_ROUTER_UTILS.url.batchoperations.storeImportForTrendyol,
      request,
      null
    );
  }

  forCreateProductCategories(
    searchKey: string
  ): Observable<ResultDto<HepsiBuradaCreateProductCategoryResultDto[]>> {
    return this.apiService.get<
      ResultDto<HepsiBuradaCreateProductCategoryResultDto[]>
    >(null, API_ROUTER_UTILS.url.hepsiburada.forCreateProductCategories, {
      searchKey: searchKey,
    });
  }

  searchBrands(searchKey: string): Observable<ResultDto<BrandResultDto[]>> {
    return this.apiService.get<ResultDto<BrandResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.trendyol.searchBrands,
      { searchKey: searchKey }
    );
  }

  categoryAttributes(
    categoryId: string
  ): Observable<ResultDto<HepsiBuradaCategoryAttributeResultDto[]>> {
    return this.apiService.get<
      ResultDto<HepsiBuradaCategoryAttributeResultDto[]>
    >(null, API_ROUTER_UTILS.url.hepsiburada.categoryAttributes, {
      categoryId: categoryId,
    });
  }
}

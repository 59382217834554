import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from 'src/app/core/utils';
import { MANAGEMENT_ROUTER_UTILS } from 'src/app/core/utils/management-router.util';
import { Menu } from 'src/app/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : true;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event:any) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next;
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  refreshMenu(userRole: string) {
    let menu: Menu[];
    switch (userRole) {
      case 'SuperAdmin':
        menu = this.SUPERADMINMENUITEMS;
        break;
      case 'CustomerAdmin':
        menu = this.MENUITEMS;
        break;
      default:
        menu = this.EMPTYMENU;
        break;
    }
    this.items.next(menu);
  }

  EMPTYMENU: Menu[] = [];

  MENUITEMS: Menu[] = [
    {
      title: 'Gösterge Paneli',
      icon: 'home',
      type: 'sub',
      active: true,
      children: [
        {
          path: `/${ROUTER_UTILS.config.home.root}/${ROUTER_UTILS.config.home.versions}`,
          title: 'Duyurular',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.dashboard.root}/${ROUTER_UTILS.config.dashboard.productDataAnalysis}`,
          title: 'ürün Analizi',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.dashboard.root}/${ROUTER_UTILS.config.dashboard.saleDataAnalysis}`,
          title: 'Sipariş Analizi',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.dashboard.root}/${ROUTER_UTILS.config.dashboard.returnDataAnalysis}`,
          title: 'İade Analizi',
          type: 'link',
        },
      ],
    },
    {
      path: `/${ROUTER_UTILS.config.products.root}`,
      title: 'Ürünler',
      icon: 'shopping-bag',
      type: 'link',
    },
    {
      path: `/${ROUTER_UTILS.config.sales.root}/${ROUTER_UTILS.config.sales.saleList}`,
      title: 'Siparişler',
      icon: 'shopping-cart',
      type: 'link',
    },
    {
      path: `/${ROUTER_UTILS.config.returns.root}/${ROUTER_UTILS.config.returns.returnList}`,
      title: 'İadeler',
      icon: 'refresh-cw',
      type: 'link',
    },
    {
      title: 'Entegrasyonlar',
      icon: 'grid',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${ROUTER_UTILS.config.integrations.root}/${ROUTER_UTILS.config.integrations.myStores}`,
          title: 'Mağazalarım',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.integrations.root}/${ROUTER_UTILS.config.integrations.myEInvoice}`,
          title: 'Fatura Entegrayonlarım',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.retailSale.root}/${ROUTER_UTILS.config.retailSale.retailSales}`,
          title: 'Perakende Satış',
          type: 'link',
        },
      ],
    },
    {
      title: 'Muhasebe İşlemleri',
      icon: 'trello',
      type: 'sub',
      active: false,
      children: [
        {
          title: 'Fatura İşlemleri',
          type: 'sub',
          active: false,
          children: [
            {
              path: `/${ROUTER_UTILS.config.accounting.root}/${ROUTER_UTILS.config.accounting.invoice}`,
              title: 'Faturalarım',
              type: 'link',
            },
          ],
        },
        {
          path: `/${ROUTER_UTILS.config.accounting.root}/${ROUTER_UTILS.config.accounting.myCustomer}`,
          title: 'Müşterilerim',
          type: 'link',
        },
        {
          title: 'Tanımlamalar',
          type: 'sub',
          active: false,
          children: [
            {
              path: `/${ROUTER_UTILS.config.accounting.root}/${ROUTER_UTILS.config.accounting.invoiceSerie}`,
              title: 'Fatura Serileri',
              type: 'link',
            },
            {
              path: `/${ROUTER_UTILS.config.accounting.root}/${ROUTER_UTILS.config.accounting.invoiceTemplate}`,
              title: 'Fatura Şablonları',
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      title: 'Toplu İşlemler',
      icon: 'git-merge',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${ROUTER_UTILS.config.batchOperations.root}/${ROUTER_UTILS.config.batchOperations.productBatch}`,
          title: 'Ürün İşlem Durumları',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.autoPricing.root}/${ROUTER_UTILS.config.autoPricing.autoPricingList}`,
          title: 'Otomatik Fiyatlandırma',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.batchOperations.root}/${ROUTER_UTILS.config.batchOperations.storeImport}`,
          title: 'Mağazadan Ürün Aktar',
          type: 'link',
        },
        // {
        //   path: `/${ROUTER_UTILS.config.batchOperations.root}/${ROUTER_UTILS.config.batchOperations.updatePrice}`,
        //   title: 'Fiyat Güncelle',
        //   type: 'link',
        // },
        // {
        //   path: `/${ROUTER_UTILS.config.batchOperations.root}/${ROUTER_UTILS.config.batchOperations.updateVat}`,
        //   title: 'Kdv Güncelle',
        //   type: 'link',
        // },
        // ,
        // {
        //   path: `/${ROUTER_UTILS.config.batchOperations.root}/${ROUTER_UTILS.config.batchOperations.deleteProdutcsAndAllRelation}`,
        //   title: 'Ürün ve İlişkilerini Sil',
        //   type: 'link',
        // },
      ],
    },
    {
      title: 'Tanımlamalar',
      icon: 'type',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${ROUTER_UTILS.config.values.root}/${ROUTER_UTILS.config.values.categories}`,
          title: 'Kategoriler',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.values.root}/${ROUTER_UTILS.config.values.brands}`,
          title: 'Markalar',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.values.root}/${ROUTER_UTILS.config.values.varianters}`,
          title: 'Varyantlar',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.values.root}/${ROUTER_UTILS.config.values.tags}`,
          title: 'Etiketler',
          type: 'link',
        }
      ],
    },
    {
      title: 'Takipler',
      icon: 'monitor',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${ROUTER_UTILS.config.tracking.root}/${ROUTER_UTILS.config.tracking.stockTransactions}`,
          title: 'Stok Hareketleri',
          type: 'link',
        },
        {
          path: `/${ROUTER_UTILS.config.tracking.root}/${ROUTER_UTILS.config.tracking.questions}`,
          title: 'Sorular ve Cevaplar',
          type: 'link',
        },
      ],
    },
    {
      title: 'Ayarlar',
      icon: 'settings',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${ROUTER_UTILS.config.settings.root}/${ROUTER_UTILS.config.settings.notifications}`,
          title: 'Bildirim Ayarları',
          type: 'link',
        },
      ],
    },
  ];

  SUPERADMINMENUITEMS: Menu[] = [
    {
      title: 'Anasayfa',
      icon: 'home',
      type: 'sub',
      active: true,
      children: [
        {
          path: `/${MANAGEMENT_ROUTER_UTILS.config.version.root}/${MANAGEMENT_ROUTER_UTILS.config.version.versions}`,
          title: 'Versiyonlar',
          type: 'link',
        },
        {
          path: `/${MANAGEMENT_ROUTER_UTILS.config.version.root}/${MANAGEMENT_ROUTER_UTILS.config.version.createVersion}`,
          title: 'Versiyon Ekle',
          type: 'link',
        },
      ],
    },
    {
      title: 'Müşteriler',
      icon: 'type',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${MANAGEMENT_ROUTER_UTILS.config.customers.root}/${MANAGEMENT_ROUTER_UTILS.config.customers.customerList}`,
          title: 'Liste',
          type: 'link',
        },
      ],
    },
    {
      title: 'Kullanıcılar',
      icon: 'grid',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${MANAGEMENT_ROUTER_UTILS.config.users.root}/${MANAGEMENT_ROUTER_UTILS.config.users.userList}`,
          title: 'Liste',
          type: 'link',
        },
        {
          path: `/${MANAGEMENT_ROUTER_UTILS.config.users.root}/${MANAGEMENT_ROUTER_UTILS.config.users.createUser}`,
          title: 'Ekle',
          type: 'link',
        },
      ],
    },
    {
      title: 'Roller',
      icon: 'airplay',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${MANAGEMENT_ROUTER_UTILS.config.roles.root}/${MANAGEMENT_ROUTER_UTILS.config.roles.roleList}`,
          title: 'Liste',
          type: 'link',
        },
      ],
    },
    {
      title: 'Mağaza Ayarları',
      icon: 'settings',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/${MANAGEMENT_ROUTER_UTILS.config.storeSettings.root}/${MANAGEMENT_ROUTER_UTILS.config.storeSettings.trendyolCategory}`,
          title: 'Trendyol Kategorileri',
          type: 'link',
        },
      ],
    },
  ];

  MEGAMENUITEMS: Menu[] = [
    {
      title: 'Error Pages',
      type: 'sub',
      active: true,
      children: [
        {
          path: '/error-page/error-400',
          title: 'Error Page 400',
          type: 'link',
        },
        {
          path: '/error-page/error-401',
          title: 'Error Page 401',
          type: 'link',
        },
        {
          path: '/error-page/error-403',
          title: 'Error Page 403',
          type: 'link',
        },
        {
          path: '/error-page/error-404',
          title: 'Error Page 404',
          type: 'link',
        },
        {
          path: '/error-page/error-500',
          title: 'Error Page 500',
          type: 'link',
        },
        {
          path: '/error-page/error-503',
          title: 'Error Page 503',
          type: 'link',
        },
      ],
    },
    {
      title: 'Authentication',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/authentication/simple-login',
          title: 'Login Simple',
          type: 'link',
        },
        {
          path: '/authentication/login-with-background-image',
          title: 'Login BG Image',
          type: 'link',
        },
        {
          path: '/authentication/login-with-background-video',
          title: 'Login BG Video',
          type: 'link',
        },
        {
          path: '/authentication/simple-register',
          title: 'Simple Register',
          type: 'link',
        },
        {
          path: '/authentication/register-with-background-image',
          title: 'Register BG Image',
          type: 'link',
        },
        {
          path: '/authentication/register-with-background-video',
          title: 'Register BG Video',
          type: 'link',
        },
      ],
    },
    {
      title: 'Usefull Pages',
      type: 'sub',
      active: false,
      children: [
        { path: '/search-pages', title: 'Search Pages', type: 'link' },
        {
          path: '/authentication/unlock-user',
          title: 'Unlock User',
          type: 'link',
        },
        {
          path: '/authentication/forgot-password',
          title: 'Forgot Password',
          type: 'link',
        },
        {
          path: '/authentication/reset-password',
          title: 'Reset Password',
          type: 'link',
        },
        { path: '/maintenance', title: 'Maintenance', type: 'link' },
      ],
    },
    {
      title: 'Email templates',
      type: 'sub',
      active: false,
      children: [
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html',
          title: 'Basic Email',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html',
          title: 'Basic With Header',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html',
          title: 'Ecomerce Template',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html',
          title: 'Email Template 2',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html',
          title: 'Ecommerce Email',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html',
          title: 'Order Success',
          type: 'extTabLink',
        },
      ],
    },
    {
      title: 'Coming Soon',
      type: 'sub',
      active: false,
      children: [
        { path: '/coming-soon/simple', title: 'Coming Simple', type: 'link' },
        {
          path: '/coming-soon/simple-with-bg-img',
          title: 'Coming BG Image',
          type: 'link',
        },
        {
          path: '/coming-soon/simple-with-bg-vid',
          title: 'Coming BG Video',
          type: 'link',
        },
      ],
    },
  ];

  LEVELMENUITEMS: Menu[] = [
    {
      path: '/file-manager',
      title: 'File Manager',
      icon: 'git-pull-request',
      type: 'link',
    },
    {
      title: 'Users',
      icon: 'users',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/user/team-details',
          title: 'All Users',
          icon: 'users',
          type: 'link',
        },
        {
          path: '/user/profile',
          title: 'User Profile',
          icon: 'users',
          type: 'link',
        },
        {
          path: '/user/edit-profile',
          title: 'Edit Profile',
          icon: 'users',
          type: 'link',
        },
      ],
    },
    { path: '/bookmarks', title: 'Bookmarks', icon: 'heart', type: 'link' },
    { path: '/calender', title: 'Calender', icon: 'calendar', type: 'link' },
    { path: '/social-app', title: 'Social App', icon: 'zap', type: 'link' },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.EMPTYMENU);
  megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
  levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);
}

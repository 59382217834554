<div class="row"  [formGroup]="autoPricingForm">
  <div class="col-md-6">
    <div class="card">
      <div class="media p-20">
        <div class="form-check radio radio-primary me-3">
          <input
            id="radio1"
            type="radio"
            name="radioName"
            value="1"
            class="form-check-input"
            formControlName="changeStepType"
            [(ngModel)]="changeStepTypeValue"
            (change)="changeChangeStepType($event)"
          /><label for="radio1" class="form-check-label"></label>
        </div>
        <div class="media-body">
          <h6 class="mt-0 mega-title-badge">Tutar</h6>
          <p>
            Örneğin; İşlem tipi Min fiyat altına seçilirse rakip min fiyat 100₺
            ise ve değişim tutarı da 5₺ ise fiyatı 95₺ olarak günceller.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="media p-20">
        <div class="form-check radio radio-primary me-3">
          <input
            id="radio2"
            type="radio"
            name="radioName"
            value="2"
            class="form-check-input"
            formControlName="changeStepType"
            [(ngModel)]="changeStepTypeValue"
            (change)="changeChangeStepType($event)"
          /><label for="radio2" class="form-check-label"></label>
        </div>
        <div class="media-body">
          <h6 class="mt-0 mega-title-badge">Yüzde</h6>
          <p>
            Örneğin; İşlem tipi Min fiyat altına seçilirse rakip min fiyat 100₺
            ise ve değişim yüzdesi da %5 ise fiyatı 95₺ olarak günceller.
          </p>
        </div>
      </div>
    </div>
  </div>

</div>


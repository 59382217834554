// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiUrl: 'https://localhost:7005/api',
  apiUrl: 'https://api.harpazar.com/api',
  firebase: {
    apiKey: 'AIzaSyD7peFuAxuP-biINCwUX4JcoBenq8ygDDY',
    authDomain: 'integration-70edf.firebaseapp.com',
    projectId: 'integration-70edf',
    storageBucket: 'integration-70edf.appspot.com',
    messagingSenderId: '310125457681',
    appId: '1:310125457681:web:11e9999f898f7d9048dcb0',
    measurementId: 'G-ZVWST57JBE',
    vapidKey:
      'BI3y8fes_wRoBFheOHJgAnEYgaMAeB6brAbn7yYWTp8CX9AIltHDRA3rcaCp0a72upx_i36Q2ftCX0Zg-JbZLJ4',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

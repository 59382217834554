import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../apis';
import { ResultDto } from '../../dtos/api';
import {
  HepsiBuradaFastCreateProductPreviewRequestDto,
  HepsiBuradaFastCreateProductPreviewResultDto,
  HepsiBuradaFastCreateProductRequestDto,
  HepsiBuradaProductInfoBySkuResultDto,
  HepsiBuradaSiteProductGroupResultDto,
  HepsiBuradaStoreProductMatchRequestDto,
  HepsiburadaOtherSellerDto,
  InfoForHepsiBuradaNewAdvertResultDto,
} from '../../dtos/hepsiburada';
import { API_ROUTER_UTILS } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class HepsiBuradaProductService {
  constructor(private apiService: ApiService) {}
  infoForHepsiBuradaNewAdvertByProdoctId(
    productId: string,
    storeId: string
  ): Observable<ResultDto<InfoForHepsiBuradaNewAdvertResultDto>> {
    return this.apiService.get<ResultDto<InfoForHepsiBuradaNewAdvertResultDto>>(
      null,
      API_ROUTER_UTILS.url.products.infoForHepsiBuradaNewAdvertByProdoctId,
      { productId: productId, storeId: storeId }
    );
  }

  fastCreateProductPreview(
    data: HepsiBuradaFastCreateProductPreviewRequestDto
  ): Observable<ResultDto<HepsiBuradaFastCreateProductPreviewResultDto>> {
    return this.apiService.post<
      ResultDto<HepsiBuradaFastCreateProductPreviewResultDto>
    >(
      API_ROUTER_UTILS.url.customerHepsiBurada.fastCreateProductPreview,
      data,
      null
    );
  }

  fastCreateProduc(
    fastCreateProducts: HepsiBuradaFastCreateProductRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.customerHepsiBurada.fastCreateProduct,
      fastCreateProducts,
      null
    );
  }

  hepsiBuradaStoreProductMatchPreview(
    productId: string,
    storeId: string
  ): Observable<ResultDto<HepsiBuradaSiteProductGroupResultDto[]>> {
    return this.apiService.get<
      ResultDto<HepsiBuradaSiteProductGroupResultDto[]>
    >(
      null,
      API_ROUTER_UTILS.url.customerHepsiBurada
        .hepsiBuradaStoreProductMatchPreview,
      {
        productId: productId,
        storeId: storeId,
      }
    );
  }

  hepsiBuradaStoreProductMatchPreviewV2(
    productId: string,
    storeId: string,
    hbSku: string
  ): Observable<ResultDto<HepsiBuradaProductInfoBySkuResultDto[]>> {
    return this.apiService.get<
      ResultDto<HepsiBuradaProductInfoBySkuResultDto[]>
    >(
      null,
      API_ROUTER_UTILS.url.customerHepsiBurada
        .hepsiBuradaStoreProductMatchPreviewV2,
      {
        productId: productId,
        storeId: storeId,
        hbSku: hbSku,
      }
    );
  }

  hepsiBuradaStoreProductMatch(
    data: HepsiBuradaStoreProductMatchRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.customerHepsiBurada.hepsiBuradaStoreProductMatch,
      data,
      null
    );
  }

  hepsiBuradaStoreProductMatchV2(
    data: HepsiBuradaStoreProductMatchRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.customerHepsiBurada.hepsiBuradaStoreProductMatchV2,
      data,
      null
    );
  }

  otherSellers(
    hepsiburadaProductId: string
  ): Observable<ResultDto<HepsiburadaOtherSellerDto>> {
    return this.apiService.get<ResultDto<HepsiburadaOtherSellerDto>>(
      null,
      API_ROUTER_UTILS.url.customerHepsiBurada.otherSellers,
      {
        hepsiburadaProductId: hepsiburadaProductId,
      }
    );
  }

  removeProductRelation(
    hepsiburadaProductId: string
  ): Observable<ResultDto> {
    return this.apiService.get<ResultDto>(
      null,
      API_ROUTER_UTILS.url.customerHepsiBurada.removeProductRelation,
      {
        hepsiburadaProductId: hepsiburadaProductId,
      }
    );
  }
}

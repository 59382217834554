import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  Output,
  type OnInit,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { PaginationResponseDto, ResultDto } from 'src/app/core/dtos/api';
import {
  AutoPricingLogV2ResultDto,
} from 'src/app/core/dtos/auto-pricing';
import {
  TrendyolAutoPricingDetailResultDto,
  TrendyolUpdateAutoPricingRequestDto,
} from 'src/app/core/dtos/trendyol-auto-pricing';
import { TrendyolAutoPricingPaginationRequestDto } from 'src/app/core/dtos/trendyol-auto-pricing/trendyol-auto-pricing-log-paginiation-request.dto';
import { TrendyolAutoPricingService } from 'src/app/core/services/auto-pricing';
import { MessageService } from 'src/app/core/services/message';
import {
  DataTableRouterUtils,
  httpErrorResponseUtil,
  stringToDouble,
  stringToInt,
} from 'src/app/core/utils';
import { markFormGroupTouched } from 'src/app/core/utils/forms/mark_form_group_touched.util';

@Component({
  selector: 'app-trendyol-auto-pricing-detail',
  templateUrl: './trendyol-auto-pricing-detail.component.html',
  styleUrls: ['./trendyol-auto-pricing-detail.component.scss'],
})
export class TrendyolAutoPricingDetailComponent implements OnInit {
  @Input() autoPricingId: string;
  @Output() updateAutoPricingOutput = new EventEmitter<boolean>();
  detailModel: TrendyolAutoPricingDetailResultDto;
  autoPricingForm: UntypedFormGroup;
  searchDto = new TrendyolAutoPricingPaginationRequestDto();
  logs: PaginationResponseDto<AutoPricingLogV2ResultDto[]>;
  ColumnMode = ColumnMode;
  loadingIndicator: boolean = true;
  constructor(
    private modalService: NgbModal,
    private trendyolAutoPricingService: TrendyolAutoPricingService,
    private messageService: MessageService,
    private formBuilder: FormBuilder
  ) {
    this.searchDto.pageNumber = DataTableRouterUtils.rowStartIndex;
    this.searchDto.pageSize = DataTableRouterUtils.apiRowCount;
    this.searchDto.orderBy = ['createdOn desc'];
  }
  ngOnInit(): void {
    this.autoPricingFormLoad();
    this.detail();
  }

  autoPricingFormLoad() {
    this.autoPricingForm = this.formBuilder.group({
      minPrice: [null, [Validators.required]],
      changeAmount: [null, [Validators.required]],
      actived: [null, [Validators.required]],
      minSellerScore: [null, [Validators.min(1), Validators.max(10)]],
    });
  }

  updateAutoPricing(postData: any) {
    markFormGroupTouched(this.autoPricingForm);
    if (this.autoPricingForm.invalid) {
      return;
    }

    var request = new TrendyolUpdateAutoPricingRequestDto(
      this.detailModel.id,
      stringToDouble(postData.minPrice.toString()),
      stringToDouble(postData.changeAmount.toString()),
      postData.actived,
      postData.minSellerScore != null
        ? stringToInt(postData.minSellerScore.toString())
        : null
    );

    this.trendyolAutoPricingService.update(request).subscribe(
      (data: ResultDto) => {
        if (data.success) {
          this.messageService.success(data.message);
          this.closeModal(true);
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
          this.closeModal(false);
        }
      },
      (error: HttpErrorResponse) => {
        this.closeModal(false);
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  detail() {
    this.trendyolAutoPricingService.detail(this.autoPricingId).subscribe(
      (data: ResultDto<TrendyolAutoPricingDetailResultDto>) => {
        if (data.success) {
          this.detailModel = data.data;
          this.fillForm();
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  fillForm() {
    this.autoPricingForm.get('minPrice').setValue(this.detailModel.minPrice);

    this.autoPricingForm
      .get('changeAmount')
      .setValue(this.detailModel.changeAmount);

    this.autoPricingForm
      .get('minSellerScore')
      .setValue(this.detailModel.minSellerScore);
    this.autoPricingForm.get('actived').setValue(this.detailModel.actived);
  }

  setPage(pageInfo:any) {
    this.searchDto.pageNumber = pageInfo.offset + 1;
    this.logSearch(this.searchDto);
  }

  logSearch(search: TrendyolAutoPricingPaginationRequestDto) {
    this.searchDto.automaticPricingId = this.autoPricingId;
    this.trendyolAutoPricingService.autoPricingLogSearch(search).subscribe(
      (data: ResultDto<PaginationResponseDto<AutoPricingLogV2ResultDto[]>>) => {
        if (data.success) {
          this.logs = data.data;
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  closeModal(fromUpdate?: boolean) {
    this.modalService.dismissAll();

    if (fromUpdate) {
      this.updateAutoPricingOutput.emit(true);
    } else {
      this.updateAutoPricingOutput.emit(false);
    }
  }

  onTabChanged($event: any) {
    if ($event.index==1) {
      this.setPage({ offset: 0 });
    }
  }
}

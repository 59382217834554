import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ResultDto } from 'src/app/core/dtos/api';
import { VarianterResultDto } from 'src/app/core/dtos/varianter';
import { VarianterService } from 'src/app/core/services/customer/varianter.service';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-form-select-varianters',
  templateUrl: './form-select-varianters.component.html',
  styleUrls: ['./form-select-varianters.component.scss'],
})
export class FormSelectVariantersComponent implements OnInit {
  @Input() varianterForm: UntypedFormGroup;
  @Output() createProductSendVarianterOutput = new EventEmitter<boolean>();
  varianters: VarianterResultDto[];
  varianterSwitch = false;
  selectedVariants: string[] = [];
  createVariantButtonDisabled: boolean = true;
  formVariants: VarianterResultDto[] = [];
  createProductSendVarianter: boolean = false;
  constructor(
    private varianterService: VarianterService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.all();
  }

  all() {
    this.varianterService.all().subscribe(
      (data: ResultDto<VarianterResultDto[]>) => {
        if (data.success) {
          this.varianters = data.data;
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }

  variantSwitchChange($event: any) {
    const checked = $event.target.checked;
    this.varianterSwitch = checked;
    if (!checked) {
      this.createVariantButtonDisabled = true;
      this.selectedVariants = [];
      this.varianterForm.get('variants').enable();
      this.removeVariantGroups();
    }
  }

  variantSelectChange($event: any) {
    if ($event.length > 0) {
      this.createVariantButtonDisabled = false;
    } else {
      this.createVariantButtonDisabled = true;
    }
  }

  get varianterGroup() {
    return this.varianterForm.controls['variantGroup'] as UntypedFormArray;
  }

  variantGroups(): UntypedFormArray {
    return this.varianterForm.get('variantGroup') as UntypedFormArray;
  }

  variantSubGroups(variantGroupIndex: number): UntypedFormArray {
    return this.variantGroups()
      .at(variantGroupIndex)
      .get('varianterSubGroup') as UntypedFormArray;
  }

  varianterImages(variantGroupIndex: number): UntypedFormArray {
    return this.variantGroups()
      .at(variantGroupIndex)
      .get('varianterImages') as UntypedFormArray;
  }

  newVariantGroup() {
    var list: UntypedFormGroup[] = [];
    for (let index = 0; index < this.selectedVariants.length; index++) {
      var variant = this.varianters.filter(
        (element) => element.id == this.selectedVariants[index]
      );
      var varianterSubAttribute = this.formBuilder.group({
        variantId: [variant[0].id, Validators.required],
        variantValueId: [null, [Validators.required]],
        variantName: [variant[0].name, []],
      });

      list.push(varianterSubAttribute);
    }

    var variantGroup = this.formBuilder.group({
      stockCode: [null, [Validators.required, RxwebValidators.unique()]],
      stockCount: [null, [Validators.required]],
      barcode: [null, [Validators.required, RxwebValidators.unique()]],
      price: [null, [Validators.required]],
      varianterImages: this.formBuilder.array([]),
      varianterSubGroup: this.formBuilder.array(list),
    });
    this.variantGroups().push(variantGroup);
  }

  createVariantRow() {
    this.newVariantGroup();
    this.varianterForm.get('variants').disable();

    if (!this.createProductSendVarianter) {
      this.createProductSendVarianterOutput.emit(true);
      this.createProductSendVarianter = true;
    }
  }

  getVarianterValues(varianterId: string) {
    var variant = this.varianters.filter(
      (element) => element.id == varianterId
    );

    return variant[0].varianterValues;
  }

  removeVariantGroups() {
    this.variantGroups().clear();
    this.varianterForm.get('variants').enable();

    this.createProductSendVarianterOutput.emit(false);
    this.createProductSendVarianter = false;
  }

  removeVariantGroup(variantGroupIndex: number) {
    this.variantGroups().removeAt(variantGroupIndex);

    if (this.variantGroups().length == 0) {
      this.varianterForm.get('variants').enable();
      this.createProductSendVarianterOutput.emit(false);
      this.createProductSendVarianter = false;
    }
  }

  createItem(data:any): UntypedFormGroup {
    return this.formBuilder.group(data);
  }

  detectFiles(event: any, variantGroupIndex: number) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.varianterImages(variantGroupIndex).push(
            this.createItem({
              file,
              url: e.target.result,
            })
          );
        };
        reader.readAsDataURL(file);
      }
    }
  }

  drop(event: CdkDragDrop<string[]>, variantGroupIndex: number) {
    moveItemInArray(
      this.varianterImages(variantGroupIndex).controls,
      event.previousIndex,
      event.currentIndex
    );
  }
}

<div class="form-group m-form__group" [formGroup]="categoryForm">
  <div
    class="input-group"
    [ngClass]="{
      'input-error': categoryForm.controls['categoryId'].invalid && submitted
    }"
  >
    <div class="input-group-prepend">
      <span class="input-group-text font-weight-bold"
        >Kategori <span class="required-input">*</span></span
      >
    </div>
    <ng-select
      class="form-control"
      placeholder="Hepsiburada Kategori Seçiniz..."
      required=""
      formControlName="categoryId"
      (search)="onSearch($event)"
      (change)="onChange($event)"
    >
      <ng-option *ngFor="let item of categories" [value]="item.categoryId">{{
        item.name
      }}</ng-option>
    </ng-select>
  </div>
</div>

<div
  class="row"
  *ngIf="
    customerProductStockTransactionsByProductId != undefined &&
    customerProductStockTransactionsByProductId.length > 0
  "
>
  <div class="card custom-card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Gerçekleştiren</th>
                <th>Tarih</th>
                <th>Barkod</th>
                <th>Varyant Barkod</th>
                <th>Tür</th>
                <th>Stok</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of customerProductStockTransactionsByProductId"
              >
                <td>{{ item.firstName }} {{ item.lastName }}</td>
                <td>{{ item.created }}</td>
                <td>{{ item.barcode }}</td>
                <td>{{ item.productVarianterBarcode }}</td>
                <td>{{ item.stockChangeTypeString }} - {{ item.stockChangeSubTypeString }}</td>
                <td>
                  <span>
                    <span
                      style="color: green"
                      *ngIf="item.stockTransactionType == 1"
                      >+</span
                    >
                    <span
                      style="color: red"
                      *ngIf="item.stockTransactionType == 2"
                      >-</span
                    >
                    {{ item.quantity }}
                    <span
                      style="color: blue"
                      class="fa fa-info-circle"
                      ngbTooltip="{{ item.description }}"
                    ></span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="row"
  *ngIf="
    customerProductStockTransactionsByProductId != undefined &&
    customerProductStockTransactionsByProductId.length == 0
  "
>
  <ngb-alert [type]="'warning'" [dismissible]="false">
    Bu ürüne ait stok hareketi bulunmamaktadır.
  </ngb-alert>
</div>

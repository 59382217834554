import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-auto-pricing-actived',
  templateUrl: './auto-pricing-actived.component.html',
  styleUrls: ['./auto-pricing-actived.component.scss'],
})
export class AutoPricingActivedComponent implements OnInit {
  @Input() autoPricingForm: UntypedFormGroup;
  activedSwitch = true;
  constructor() {}

  ngOnInit(): void {}
}

<div class="form-group m-form__group" [formGroup]="autoPricingForm">
  <div
    class="input-group"
    [ngClass]="{
      'input-error':
        autoPricingForm.get('minSellerScore').invalid &&
        submitted
    }"
  >
    <div class="input-group-prepend">
      <span class="input-group-text"
      [ngClass]="required != undefined && required ? 'font-weight-bold' : ''"
        >Min. Rakip Mağaza Puanı</span
      >
    </div>
    <input
      class="form-control"
      [type]="'number'"
      formControlName="minSellerScore"
      onlyNumbers
      [max]="10"
      [min]="1"
    />
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ResultDto } from 'src/app/core/dtos/api';
import { TrendyolOtherSellerDto } from 'src/app/core/dtos/trendyol-auto-pricing';
import { TrendyolAutoPricingService } from 'src/app/core/services/auto-pricing';
import { ProductService } from 'src/app/core/services/customer';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-trendyol-other-sellers',
  templateUrl: './trendyol-other-sellers.component.html',
  styleUrls: ['./trendyol-other-sellers.component.scss'],
})
export class TrendyolOtherSellersComponent implements OnInit {
  @Input() trendyolProductId: string;
  trendyolOtherSellersDto: TrendyolOtherSellerDto[];

  constructor(
    private trendyolAutoPricingService: TrendyolAutoPricingService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.trendyolOtherSellers(this.trendyolProductId);
  }

  trendyolOtherSellers(id: string) {
    this.trendyolAutoPricingService.trendyolOtherSellers(id).subscribe(
      (data: ResultDto<TrendyolOtherSellerDto[]>) => {
        if (data.success) {
          this.trendyolOtherSellersDto = data.data;
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorText = httpErrorResponseUtil(error);
        this.messageService.error(errorText);
      }
    );
  }
}

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Otomatik Fiyatlandırma</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <mat-tab-group dynamicHeight (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="Detay">
      <br />
      <form class="theme-form" [formGroup]="autoPricingForm">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Min. Fiyat</mat-label>
              
              <input
                type="text"
                formControlName="minPrice"
                matInput
                onlyDecimalInput
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Değişim Tutarı</mat-label>
              <input
                type="text"
                formControlName="changeAmount"
                matInput
                onlyDecimalInput
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Min. Satıcı Puanı</mat-label>
              <input
                type="number"
                formControlName="minSellerScore"
                matInput
                onlyNumbers
                [min]="1"
                [max]="10"
              />
              <mat-hint align="start"
                ><strong
                  >(İsteğe bağlı olarak) Referans alınacak mininum satıcı
                  puanı</strong
                >
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <app-auto-pricing-actived [autoPricingForm]="autoPricingForm">
            </app-auto-pricing-actived>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <button
              type="submit"
              class="btn btn-primary"
              (click)="updateAutoPricing(autoPricingForm.value)"
            >
              Güncelle
            </button>
          </div>
        </div>
      </form>
    </mat-tab>
    <mat-tab label="Log">
      <br />
      <div class="custom-datatable noscroll">
        <ngx-datatable
          *ngIf="logs != undefined"
          class="bootstrap"
          [rows]="logs.data"
          [loadingIndicator]="loadingIndicator"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [externalPaging]="true"
          [count]="logs.totalCount"
          [offset]="logs.currentPage - 1"
          [limit]="logs.pageSize"
          (page)="setPage($event)"
        >
          <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template>
              <strong>Fiyat Bilgileri</strong>
            </ng-template>
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div *ngIf="row['beforePrice'] != null">
                <b>Değişimden Önceki Fiyat: </b>{{ row["beforePrice"] }}
              </div>
              <div *ngIf="row['afterPrice'] != null">
                <b>Değişimden Sonraki Fiyat: </b>{{ row["afterPrice"] }}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template>
              <strong>Durum</strong>
            </ng-template>
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div *ngIf="row['successed']" style="color: green">
                {{ row["message"] }}
              </div>
              <div *ngIf="!row['successed']" style="color: red">
                <div *ngFor="let item of row['errorTextList']">
                  <span>- {{ item }}</span>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template>
              <strong>Çalıştırılma Bilgisi</strong>
            </ng-template>
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div *ngIf="row['executed']" style="color: green">
                Çalıştırıldı
              </div>
              <div *ngIf="row['executed']">
                <b>Çalıştırılma Zamanı:</b> {{ row["executedOnString"] }}
              </div>
              <div *ngIf="!row['executed']" style="color: red">
                Çalıştırılmadı
              </div>
              <div *ngIf="!row['executed']">
                {{ row["createdOnString"] }}
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="modal-footer">
  <button class="btn btn-light" (click)="closeModal()">Kapat</button>
</div>

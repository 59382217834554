import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/apis';
import { PaginationResponseDto, ResultDto } from 'src/app/core/dtos/api';
import {
  AutoPricingChangeActivedResultDto,
  AutoPricingLogPaginationResultDto,
  AutoPricingLogV2ResultDto,
  AutoPricingPaginationResultDto,
  CreateTrendyolAutoPricingRequestDto,
} from 'src/app/core/dtos/auto-pricing';
import {
  TrendyolAutoPricingDetailResultDto,
  TrendyolAutoPricingLogResultDto,
  TrendyolOtherSellerDto,
  TrendyolUpdateAutoPricingRequestDto,
} from 'src/app/core/dtos/trendyol-auto-pricing';
import { TrendyolAutoPricingPaginationRequestDto } from 'src/app/core/dtos/trendyol-auto-pricing/trendyol-auto-pricing-log-paginiation-request.dto';
import { API_ROUTER_UTILS } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root',
})
export class TrendyolAutoPricingService {
  constructor(private apiService: ApiService) {}

  trendyolOtherSellers(
    id: string
  ): Observable<ResultDto<TrendyolOtherSellerDto[]>> {
    return this.apiService.get<ResultDto<TrendyolOtherSellerDto[]>>(
      null,
      API_ROUTER_UTILS.url.autoPricing.trendyolOtherSellers,
      { id: id }
    );
  }

  createTrendyolAutoPricing(
    request: CreateTrendyolAutoPricingRequestDto
  ): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.autoPricing.createTrendyolAutoPricing,
      request,
      null,
      {}
    );
  }

  trendyolAutoPricingLogs(
    trendyolAutoPricingId: string
  ): Observable<ResultDto<TrendyolAutoPricingLogResultDto[]>> {
    return this.apiService.get<ResultDto<TrendyolAutoPricingLogResultDto[]>>(
      null,
      API_ROUTER_UTILS.url.autoPricing.trendyolAutoPricingLogs,
      { trendyolAutoPricingId: trendyolAutoPricingId }
    );
  }

  trendyolAutoPricingChangeActived(
    trendyolAutoPricingId: string,
    actived: boolean
  ): Observable<ResultDto<AutoPricingChangeActivedResultDto>> {
    return this.apiService.get<ResultDto<AutoPricingChangeActivedResultDto>>(
      null,
      API_ROUTER_UTILS.url.autoPricing.trendyolAutoPricingChangeActived,
      { trendyolAutoPricingId: trendyolAutoPricingId, actived: actived }
    );
  }

  detail(
    trendyolAutoPricingId: string
  ): Observable<ResultDto<TrendyolAutoPricingDetailResultDto>> {
    return this.apiService.get<ResultDto<TrendyolAutoPricingDetailResultDto>>(
      null,
      API_ROUTER_UTILS.url.autoPricing.trendyolDeail,
      { trendyolAutoPricingId: trendyolAutoPricingId }
    );
  }

  update(request: TrendyolUpdateAutoPricingRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.autoPricing.updateTrendyolAutoPricing,
      request,
      null,
      {}
    );
  }

  autoPricingLogSearch(
    request: TrendyolAutoPricingPaginationRequestDto
  ): Observable<ResultDto<PaginationResponseDto<AutoPricingLogV2ResultDto[]>>> {
    return this.apiService.post<ResultDto<PaginationResponseDto<AutoPricingLogV2ResultDto[]>>>(
      API_ROUTER_UTILS.url.autoPricing.trendyolAutoPricingLogSearch,
      request,
      null
    );
  }
}
